import { VFC } from "react";

import { Checkbox, FormControl, FormControlLabel, InputLabel, TextInput } from "@portex-pro/ui-components";
import PositiveNumberInput from "components/PositiveNumberInput";
import Text from "components/Text";
import { FeeTerms, FormData } from "features/generate-bol/store/generateBOLSlice";
import { Trans, useTranslation } from "react-i18next";

interface FinalDetailsViewProps {
  declaredValueAmount?: number;
  declaredValueUnit?: string;
  remitCodTo?: string;
  codAmount?: number;
  feeTerms?: FeeTerms;
  isHazardous?: boolean;

  onChange: (data: Partial<FormData>) => void;
}

const FinalDetailsView: VFC<FinalDetailsViewProps> = (props) => {
  const { onChange, remitCodTo, codAmount, declaredValueAmount, declaredValueUnit, feeTerms, isHazardous } = props;
  const { t } = useTranslation("bolGeneration");

  return (
    <div className="flex flex-col bg-white">
      <div className="p-5 border-b border-grey-300">
        <Text
          size="small"
          typographyProps={{
            style: {
              lineHeight: "40px",
            },
          }}
        >
          <Trans
            t={t}
            i18nKey="declaredAmountStatement"
            components={[
              <PositiveNumberInput
                value={declaredValueAmount}
                disableError
                allowFloat
                decimalPlace={2}
                style={{ marginLeft: "0.25rem", marginRight: "0.25rem" }}
                startIcon={
                  <Text size="small" typographyProps={{ color: "textSecondary" }}>
                    $
                  </Text>
                }
                onChange={(declaredValueAmount) => onChange({ declaredValueAmount })}
              />,
              <TextInput
                value={declaredValueUnit}
                onChange={(event) => onChange({ declaredValueUnit: event.target.value })}
                style={{ marginLeft: "0.25rem", marginRight: "0.25rem" }}
              />,
            ]}
          />
        </Text>
      </div>
      <div className="flex flex-row p-5 space-x-4">
        <FormControl className="[&&]:min-w-[300px]">
          <InputLabel>{t("remitCodTo")}</InputLabel>
          <TextInput value={remitCodTo} onChange={(event) => onChange({ remitCodTo: event.target.value })} />
        </FormControl>
      </div>
      <div className="flex flex-row p-5 space-x-4 space-y-0">
        <FormControl>
          <InputLabel>{t("codAmount")}</InputLabel>
          <PositiveNumberInput
            value={codAmount}
            onChange={(codAmount) => onChange({ codAmount })}
            decimalPlace={2}
            allowFloat
            disableError
            required={false}
            startIcon={
              <Text size="small" typographyProps={{ color: "textSecondary" }}>
                $
              </Text>
            }
          />
        </FormControl>
        <FormControl>
          <InputLabel>{t("feeTerms")}</InputLabel>
          <div className="flex flex-row">
            <FormControlLabel
              control={
                <Checkbox
                  checked={feeTerms === "prepaid"}
                  onChange={(_, checked) => onChange({ feeTerms: checked ? "prepaid" : undefined })}
                />
              }
              label={t("prepaid")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={feeTerms === "collect"}
                  onChange={(_, checked) => onChange({ feeTerms: checked ? "collect" : undefined })}
                />
              }
              label={t("collect")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={feeTerms === "customerCheckAcceptable"}
                  onChange={(_, checked) => onChange({ feeTerms: checked ? "customerCheckAcceptable" : undefined })}
                />
              }
              label={t("customerCheckAcceptable")}
            />
          </div>
        </FormControl>
        <FormControl>
          <InputLabel>{t("hazardousGoods")}</InputLabel>
          <FormControlLabel
            control={<Checkbox checked={isHazardous} onChange={(_, checked) => onChange({ isHazardous: checked })} />}
            label={t("containsHazardousGoods")}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default FinalDetailsView;
