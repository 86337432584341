export enum TrailerSizeEnum {
  T_26 = "T_26",
  T_40 = "T_40",
  T_48 = "T_48",
  T_53 = "T_53",
  C_20 = "C_20",
  C_40 = "C_40",
  C_20_HC = "C_20_HC",
  C_40_HC = "C_40_HC",
  C_45_HC = "C_45_HC",
  NA = "NA",
}

export type TrailerSize = `${TrailerSizeEnum}`;

export type TrailerSizeDrayage = Extract<TrailerSize, "C_20" | "C_40" | "C_20_HC" | "C_40_HC" | "C_45_HC">;
