import { Fragment, ReactElement } from "react";

import { SvgBulkFilled, SvgDryVanFilled, SvgFlatbedFilled, SvgReeferFilled } from "@portex-pro/ui-components";
import OptionalMaybe from "types/OptionalMaybe";
import { TrailerType, TrailerTypeEnum } from "types/TrailerType";

interface TrailerTypeProps {
  type: OptionalMaybe<TrailerType>;
  large?: boolean;
  small?: boolean;
}

const styles = {
  small: {
    width: "2rem",
    height: "2rem",
  },
  medium: {
    width: "2.5rem",
    height: "2.5rem",
  },
  large: {
    width: "3.75rem",
    height: "3.75rem",
  },
};

const TrailerTypeIcon = ({ type, large = false, small = false }: TrailerTypeProps): ReactElement => {
  const style = (() => {
    // priority to larger size prop
    if (large) {
      return styles.large;
    } else if (small) {
      return styles.small;
    } else {
      return styles.medium;
    }
  })();

  let TrailerTypeIcon = null;

  switch (type) {
    case TrailerTypeEnum.BOX_TRUCK:
    case TrailerTypeEnum.CARGO_VAN:
    case TrailerTypeEnum.CONESTOGA:
    case TrailerTypeEnum.DRY_VAN:
    case TrailerTypeEnum.DUMP:
    case TrailerTypeEnum.HOTSHOT:
    case TrailerTypeEnum.PARTIAL_LOAD:
    case TrailerTypeEnum.SPRINTER_VAN:
    case TrailerTypeEnum.OTHER:
    case TrailerTypeEnum.NA_PARTNERS_CHOICE: {
      TrailerTypeIcon = SvgDryVanFilled;
      break;
    }
    case TrailerTypeEnum.REEFER:
    case TrailerTypeEnum.FOOD_GRADE_TANK:
    case TrailerTypeEnum.DRAYAGE_REEFER:
      TrailerTypeIcon = SvgReeferFilled;
      break;
    case TrailerTypeEnum.STEP_DECK:
    case TrailerTypeEnum.FLATBED:
    case TrailerTypeEnum.RGN:
    case TrailerTypeEnum.DRAYAGE: {
      TrailerTypeIcon = SvgFlatbedFilled;
      break;
    }
    case TrailerTypeEnum.BULK: {
      TrailerTypeIcon = SvgBulkFilled;
      break;
    }
    default: {
      return <Fragment />;
    }
  }

  return <TrailerTypeIcon style={style} />;
};

export default TrailerTypeIcon;
