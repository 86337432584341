import { ChangeEvent, ReactElement, useEffect, useMemo, useState } from "react";

import { gql, TypedDocumentNode, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  ButtonGroup,
  FloatInput,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  NumberInput,
  QuoteUrgency,
  Radio,
  RadioGroup,
  TextInput,
  Typography,
} from "@portex-pro/ui-components";
import FormStringConcat from "@portex-pro/ui-components/Forms/FormStringConcat";
import { getPalletMultiplier } from "features/shipments/utils/getPalletMultiplier";
import { ResourceKey } from "i18next";
import capitalize from "lodash/capitalize";
import find from "lodash/find";
import keys from "lodash/keys";
import round from "lodash/round";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import upperCase from "lodash/upperCase";
import { DateTime } from "luxon";
import { Trans, useTranslation } from "react-i18next";
import { TrailerSizeEnum } from "types/TrailerSize";
import getQuoteRequestInfo from "utils/getQuoteRequestInfo";
import StopsWithGoogleDistance from "views/stops/StopsWithGoogleDistance";

import {
  DriverPreference,
  FtlLoadSpec,
  FtlTrailerType,
  PackagingType,
  Query,
  QueryGetQuoteRequestArgs,
  QuoteRequest,
  TrailerSize,
  WeightUnit,
  PalletType,
} from "../../../../../../../api/types/generated-types";
import LayoutColumnTwo from "../../../../../../../components/LayoutColumnTwo";
import Loading from "../../../../../../../components/Loading";
import TemperatureInput from "../../../../../../../components/TemperatureInput";
import ThrottledTextInput, { ThrottledTextInputProps } from "../../../../../../../components/ThrottledTextInput";
import { useOnApolloError } from "../../../../../../../hooks/useOnApolloError";
import { deserializeNotes } from "../../../../../../../utils/deserializeNotes";
import { serializeNotes } from "../../../../../../../utils/serializeNotes";
import { toLuxon } from "../../../../../../../utils/toLuxon";
import { toLuxonRelative } from "../../../../../../../utils/toLuxonRelative";
import FormSelectView from "../../../components/FormSelectView";
import Section from "../../../components/Section";
import { StepperStepProps } from "../../../components/StepperStep";
import StepTitle from "../../../components/StepTitle";
import { packagingTypeList } from "../../../constants/packagingTypes";
import { palletTypeList } from "../../../constants/palletType";
import { useGoToStep, useStepStates } from "../../../hooks/useStep";
import { ftlTrailerTypeList } from "../constants/ftlTrailerType";
import { getUrgencyOptions } from "../utils/getUrgencyOptions";
import DriverPreferenceView from "./DriverPreferenceView";

type LoadAttributesStepProps = StepperStepProps;

type QuoteRequestPatch = {
  deadline_respond_at?: DateTime | null;
  note?: QuoteRequest["note"];
  is_hazardous?: QuoteRequest["is_hazardous"];
  hazardous_goods_details?: QuoteRequest["hazardous_goods_details"];
  reference_number?: QuoteRequest["reference_number"];
  type?: QuoteRequest["type"];
  goods_value?: QuoteRequest["goods_value"];
};

const ADDRESS = gql`
  fragment LoadAttributesStepFTL_Address on Address {
    id
    city
    province_code
    name
  }
`;

const STOP = gql`
  fragment LoadAttributesStepFTL_Stop on Stop {
    id
    position
    note
    start
    end
    note
    is_time_tbd
    reference_number
    distance_from_prior_stop
    address {
      ...LoadAttributesStepFTL_Address
    }
  }
  ${ADDRESS}
`;

const UPDATE_FTL_LOAD_SPEC = gql`
  mutation ($input: MutateFtlLoadSpecInput!) {
    updateFtlLoadSpec(input: $input) {
      id
    }
  }
`;

const UPDATE_QUOTE_REQUEST = gql`
  mutation ($input: UpdateQuoteRequestInput!) {
    updateQuoteRequest(input: $input) {
      id
    }
  }
`;

const LOAD_ATTRIBUTES_STEP_FTL_LOAD_SPEC = gql`
  fragment LoadAttributesStepFTL_FtlLoadSpec on FtlLoadSpec {
    id
    commodities
    is_palletized
    max_temp
    min_temp
    packaging_type
    packing_count
    pallet_count
    pallet_type
    total_weight
    trailer_size
    trailer_type
    truck_quantity
    weight_unit
    driver_preference
  }
`;

const LOAD_ATTRIBUTES_STEP_QUOTE_REQUEST = gql`
  fragment LoadAttributesStepFTL_QuoteRequest on QuoteRequest {
    id
    type
    deadline_respond_at
    note
    is_hazardous
    hazardous_goods_details
    ftl_load_specs {
      ...LoadAttributesStepFTL_FtlLoadSpec
    }
    stops {
      ...LoadAttributesStepFTL_Stop
    }
    reference_number
    goods_value
  }
  ${LOAD_ATTRIBUTES_STEP_FTL_LOAD_SPEC}
  ${STOP}
`;

const GET_QUOTE_REQUEST: TypedDocumentNode<Pick<Query, "getQuoteRequest">, QueryGetQuoteRequestArgs> = gql`
  query ($id: ID!) {
    getQuoteRequest(id: $id) {
      ...LoadAttributesStepFTL_QuoteRequest
    }
  }
  ${LOAD_ATTRIBUTES_STEP_QUOTE_REQUEST}
`;

const useStyles = makeStyles(() => ({
  urgency: {
    padding: "9px",
    marginTop: "4px",
    "& .MuiBox-root": {
      justifyContent: "space-between",
      width: "100%",
    },
  },
}));

const LoadAttributesStep = ({
  active,
  prevStep,
  nextStep,
  goToStep,
  onGoToStep,
  onLoading,
}: LoadAttributesStepProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "LoadAttributesStep" });

  const step = useStepStates({ onLoading });
  const { dirty, setDirty, loading, setLoading, quoteRequestId } = step;
  const classes = useStyles();
  const [urgencyOptions] = useState(getUrgencyOptions());

  const [quoteRequestPatch, setQuoteRequestPatch] = useState<QuoteRequestPatch>({});
  const [ftlLoadSpecPatch, setFtlLoadSpecPatch] = useState<Partial<FtlLoadSpec>>({});

  const [updateFtlLoadSpec, { loading: updatingFtlLoadSpec }] = useMutation(UPDATE_FTL_LOAD_SPEC, {
    onError: onApolloError("updateFtlLoadSpec"),
  });
  const [updateQuoteRequest, { loading: updatingQuoteRequest }] = useMutation(UPDATE_QUOTE_REQUEST, {
    onError: onApolloError("updateQuoteRequest"),
  });

  const { data, loading: fetchingQuoteRequest } = useQuery(GET_QUOTE_REQUEST, {
    variables: { id: quoteRequestId },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getQuoteRequest"),
    skip: !active || (!quoteRequestId && active),
  });
  const quoteRequest = data?.getQuoteRequest;
  const { ftl_load_specs } = data?.getQuoteRequest || {};
  const [ftlLoadSpec] = ftl_load_specs || [];
  const ftlLoadSpecId = ftlLoadSpec?.id;

  const patchedFtlLoadSpec = useMemo(
    () => ({
      ...(ftlLoadSpec || {}),
      ...ftlLoadSpecPatch,
    }),
    [ftlLoadSpecPatch, ftlLoadSpec]
  );

  const patchedQuoteRequest = useMemo<QuoteRequestPatch>(() => {
    const { deadline_respond_at, ...rest } = quoteRequest || {};

    const deadlineRespondAtLuxon = deadline_respond_at ? toLuxon(deadline_respond_at) : null;

    return {
      deadline_respond_at: deadlineRespondAtLuxon,
      ...rest,
      ...quoteRequestPatch,
    };
  }, [quoteRequest, quoteRequestPatch]);

  const {
    commodities,
    driver_preference,
    is_palletized,
    max_temp,
    min_temp,
    packaging_type,
    packing_count,
    pallet_count,
    pallet_type,
    total_weight,
    weight_per_unit,
    trailer_size,
    trailer_type,
    truck_quantity,
    unit_count,
    weight_unit,
  } = patchedFtlLoadSpec || {};

  useEffect(() => {
    if (!ftlLoadSpec) return;

    if (!is_palletized) {
      setFtlLoadSpecPatch((patch) => ({ ...patch, pallet_type: null, pallet_count: null }));
    }
  }, [ftlLoadSpec, is_palletized]);

  const onSelectDriverPreference = (driverPreference: DriverPreference): void => {
    setFtlLoadSpecPatch((current) => ({ ...current, driver_preference: driverPreference }));
    setDirty(true);
  };

  const handleBackOrNext = async () => {
    let success = true;

    if (dirty) {
      const note = serializeNotes(quoteRequestPatch.note);
      setLoading(true);
      const result = await Promise.all([
        keys(ftlLoadSpecPatch).length
          ? updateFtlLoadSpec({
              variables: {
                input: {
                  ...ftlLoadSpecPatch,
                  id: ftlLoadSpecId,
                },
              },
            })
          : null,
        keys(quoteRequestPatch).length
          ? updateQuoteRequest({
              variables: {
                input: {
                  ...quoteRequestPatch,
                  id: quoteRequestId,
                  note,
                },
              },
            })
          : null,
      ]);

      success = result.every((r) => !r?.errors);
      setLoading(false);
      if (success) setDirty(false);
    }
    return success;
  };

  useGoToStep({ ...step, active, goToStep, onGoToStep, handleSync: handleBackOrNext });

  if (!active) return <></>;

  if (fetchingQuoteRequest || !data) return <Loading spinnerOnly height="auto" />;

  const { deadline_respond_at, note, is_hazardous, hazardous_goods_details, reference_number, type, goods_value } =
    patchedQuoteRequest;

  const selectedUrgencyOption = find(
    urgencyOptions,
    (option) =>
      option.value.toUTC().day === deadline_respond_at?.toUTC().day &&
      option.value.toUTC().hour === deadline_respond_at?.toUTC().hour
  );

  const renderUrgencyLabel = (id: string | undefined) => {
    const option = find(urgencyOptions, { id });
    const fallback = toLuxonRelative(deadline_respond_at);
    return option
      ? t("shipper:urgencyLabel", {
          type: capitalize(type ?? ""),
          label: option.label,
        })
      : t("shipper:urgencyLabel", {
          type: capitalize(type ?? ""),
          label: fallback,
        });
  };

  const handleClearUrgency = () => {
    setQuoteRequestPatch((current) => ({ ...current, deadline_respond_at: null }));
    setDirty(true);
  };

  const handleChangeUrgency = (id: string) => {
    const selected = find(urgencyOptions, { id });
    setQuoteRequestPatch((current) => ({ ...current, deadline_respond_at: selected?.value }));
    setDirty(true);
  };

  const onReferenceNumberChange = ({
    target: { value: reference_number },
  }: ChangeEvent<{ name?: string; value: string }>) => {
    setQuoteRequestPatch((patch) => ({ ...patch, reference_number }));
    setDirty(true);
  };

  const handleClearPackagingType = () => {
    setFtlLoadSpecPatch((current) => ({ ...current, packaging_type: null }));
    setDirty(true);
  };

  const handleCommoditiesChange = (commodities: string) => {
    setFtlLoadSpecPatch((patch) => ({ ...patch, commodities }));
    setDirty(true);
  };

  const togglePackagingType = (packagingType: PackagingType) => {
    setFtlLoadSpecPatch((patch) => ({ ...patch, packaging_type: packagingType }));
    setDirty(true);
  };

  const togglePalletType = (palletType: PalletType) => {
    setFtlLoadSpecPatch((patch) => ({ ...patch, pallet_type: palletType }));
    setDirty(true);
  };

  const recalculateTotalWeight = ({
    is_palletized,
    packing_count,
    pallet_count,
    total_weight,
    unit_count,
    weight_per_unit,
  }: Partial<typeof patchedFtlLoadSpec>) =>
    getPalletMultiplier(is_palletized, pallet_count || 0) *
      (packing_count || 0) *
      (unit_count || 0) *
      (weight_per_unit || 0) || total_weight;

  const recalculateWeightPerUnit = ({
    is_palletized,
    packing_count,
    pallet_count,
    total_weight,
    unit_count,
    weight_per_unit,
  }: Partial<typeof patchedFtlLoadSpec>) => {
    const totalUnits = getPalletMultiplier(is_palletized, pallet_count || 0) * (packing_count || 0) * (unit_count || 0);

    return totalUnits ? round((total_weight || 0) / totalUnits, 3) : weight_per_unit;
  };

  const onPackingCountChange = ({
    target: { value: packing_count },
  }: ChangeEvent<{ name?: string; value: string }>) => {
    const positivePackingCount = Number(packing_count.replace(/\D/g, ""));

    setFtlLoadSpecPatch((patch) => ({
      ...patch,
      packing_count: positivePackingCount,
      total_weight: recalculateTotalWeight({ ...patchedFtlLoadSpec, packing_count: positivePackingCount }),
    }));
    setDirty(true);
  };

  const onUnitCountChange = ({ target: { value: unit_count } }: ChangeEvent<{ name?: string; value: string }>) => {
    const positiveUnitCount = Number(unit_count.replace(/\D/g, ""));

    setFtlLoadSpecPatch((patch) => ({
      ...patch,
      unit_count: positiveUnitCount,
      total_weight: recalculateTotalWeight({ ...patchedFtlLoadSpec, unit_count: positiveUnitCount }),
    }));
    setDirty(true);
  };

  const onPalletCountChange = ({ target: { value: pallet_count } }: ChangeEvent<{ name?: string; value: string }>) => {
    const positivePalletCount = Number(pallet_count.replace(/\D/g, ""));

    setFtlLoadSpecPatch((patch) => ({
      ...patch,
      pallet_count: positivePalletCount,
      total_weight: recalculateTotalWeight({ ...patchedFtlLoadSpec, pallet_count: positivePalletCount }),
    }));
    setDirty(true);
  };

  const onWeightChange = ({ target: { value: total_weight } }: ChangeEvent<{ name?: string; value: string }>) => {
    const positiveWeight = Math.abs(Number(total_weight));

    if (isNaN(positiveWeight)) {
      return;
    }

    setFtlLoadSpecPatch((patch) => ({
      ...patch,
      total_weight: positiveWeight,
      weight_per_unit: recalculateWeightPerUnit({ ...patchedFtlLoadSpec, total_weight: positiveWeight }),
    }));
    setDirty(true);
  };

  const onWeightPerUnitChange = ({
    target: { value: weight_per_unit },
  }: ChangeEvent<{ name?: string; value: string }>) => {
    const positiveWeight = Math.abs(Number(weight_per_unit));

    if (isNaN(positiveWeight)) {
      return;
    }

    setFtlLoadSpecPatch((patch) => ({
      ...patch,
      weight_per_unit: positiveWeight,
      total_weight: recalculateTotalWeight({ ...patchedFtlLoadSpec, weight_per_unit: positiveWeight }),
    }));
    setDirty(true);
  };

  const toggleTrailerType = (trailerType: FtlTrailerType) => {
    setFtlLoadSpecPatch((patch) => {
      let trailer_size = patch.trailer_size;

      if (
        trailer_type === FtlTrailerType.Drayage ||
        trailer_type === FtlTrailerType.DrayageReefer ||
        trailerType === FtlTrailerType.Drayage ||
        trailerType === FtlTrailerType.DrayageReefer
      ) {
        trailer_size = null;
      }

      if (trailerType === FtlTrailerType.SprinterVan || trailerType === FtlTrailerType.Rgn) {
        trailer_size = TrailerSize.Na;
      }

      if (trailerType === FtlTrailerType.BoxTruck) {
        trailer_size = null;
      }

      return { ...patch, trailer_type: trailerType, trailer_size };
    });

    setDirty(true);
  };

  const toggleWeightUnit = (weight_unit: WeightUnit) => () => {
    setFtlLoadSpecPatch((patch) => ({ ...patch, weight_unit }));
    setDirty(true);
  };

  const toggleIsHazardous = (is_hazardous: boolean) => () => {
    setQuoteRequestPatch((patch) => ({ ...patch, is_hazardous, hazardous_goods_details: "" }));
    setDirty(true);
  };

  const onTrailerSizeChange = ({ trailer_size: nextTrailerSize }: { trailer_size: TrailerSize }) => {
    setFtlLoadSpecPatch((patch) => ({
      ...patch,
      trailer_size: nextTrailerSize,
    }));
    setDirty(true);
  };

  const onTruckQuantityChange = ({ target: { value } }: ChangeEvent<{ name?: string; value: string }>) => {
    const positiveTruckQuantity = Number(value.replace(/\D/g, ""));
    setFtlLoadSpecPatch((patch) => ({
      ...patch,
      truck_quantity: positiveTruckQuantity,
    }));
    setDirty(true);
  };

  const onMinTempChange = (value: number) => {
    setFtlLoadSpecPatch((patch) => ({ ...patch, min_temp: value }));
    setDirty(true);
  };

  const onMaxTempChange = (value: number) => {
    setFtlLoadSpecPatch((patch) => ({ ...patch, max_temp: value }));
    setDirty(true);
  };

  const onNoteChange: ThrottledTextInputProps["onChange"] = (note) => {
    setQuoteRequestPatch((patch) => ({ ...patch, note }));
    setDirty(true);
  };

  const onHazardousNotesChange = ({
    target: { value: hazardous_goods_details },
  }: ChangeEvent<{ name?: string; value: string }>) => {
    setQuoteRequestPatch((patch) => ({ ...patch, hazardous_goods_details }));
    setDirty(true);
  };

  const toggleIsPalletized = (is_palletized: boolean) => () => {
    setFtlLoadSpecPatch((patch) => ({
      ...patch,
      is_palletized,
    }));
    setDirty(true);
  };

  const handleChangeGoodsValue = (value: QuoteRequestPatch["goods_value"]) => {
    setQuoteRequestPatch((current) => ({ ...current, goods_value: value }));
    setDirty(true);
  };

  const truckQuantityField = (
    <NumberInput
      translate="no"
      label={t("shipper:loadAttributesStep.numberOfTrucks")}
      value={truck_quantity || 1}
      margin="dense"
      fullWidth
      onlyValidInput
      disableError
      validator="PositiveInt"
      onChange={onTruckQuantityChange}
    />
  );

  const packingMethodField = (
    <FormControl margin="dense" fullWidth>
      <FormLabel>{t("shipper:packingMethod")}</FormLabel>
      <ButtonGroup fullWidth color={"primary"}>
        <Button onClick={toggleIsPalletized(true)} className={is_palletized ? "Ptx-selected" : ""}>
          {t("shipper:palletized")}
        </Button>
        <Button onClick={toggleIsPalletized(false)} className={!is_palletized ? "Ptx-selected" : ""}>
          {t("shipper:floorLoaded")}
        </Button>
      </ButtonGroup>
    </FormControl>
  );

  const totalWeightField = (
    <NumberInput
      translate="no"
      label={t("shipper:loadAttributesStep.totalShipmentWeight")}
      value={total_weight ?? undefined}
      margin="dense"
      fullWidth
      onlyValidInput
      validator="PositiveFloat"
      onChange={onWeightChange}
    />
  );

  const weightPerUnitField = (
    <NumberInput
      translate="no"
      label={t("shipper:loadAttributesStep.weightPerUnit")}
      value={weight_per_unit ?? undefined}
      margin="dense"
      fullWidth
      onlyValidInput
      disableError
      validator="PositiveFloat"
      onChange={onWeightPerUnitChange}
    />
  );

  const weightUnitField = (
    <FormControl fullWidth margin="dense">
      <FormLabel>&nbsp;</FormLabel>
      <ButtonGroup color={"primary"} fullWidth>
        <Button
          key={WeightUnit.Lb}
          onClick={toggleWeightUnit(WeightUnit.Lb)}
          className={WeightUnit.Lb === weight_unit ? "Ptx-selected" : ""}
        >
          {toLower(WeightUnit.Lb)}
        </Button>
        <Button
          key={WeightUnit.Kg}
          onClick={toggleWeightUnit(WeightUnit.Kg)}
          className={WeightUnit.Kg === weight_unit ? "Ptx-selected" : ""}
        >
          {toLower(WeightUnit.Kg)}
        </Button>
      </ButtonGroup>
    </FormControl>
  );

  const hazardousToggleField = (
    <FormControl fullWidth margin={"dense"}>
      <FormLabel>{t("shipper:hazardousGoods")}</FormLabel>
      <RadioGroup
        row
        aria-label="gender"
        name={t("shipper:hazardousGoods")}
        value={is_hazardous}
        style={{ marginLeft: "6px" }}
      >
        <FormControlLabel
          value={false}
          control={<Radio name="is_hazardous" />}
          checked={!is_hazardous}
          onClick={toggleIsHazardous(false)}
          label={t("common:no")}
        />
        <FormControlLabel
          value={true}
          control={<Radio name="is_hazardous" />}
          checked={!!is_hazardous}
          onClick={toggleIsHazardous(true)}
          label={t("common:yes")}
        />
      </RadioGroup>
    </FormControl>
  );

  const trailerTypeField = (
    <FormSelectView
      label={t("shipper:trailerType")}
      highlight={!trailer_type}
      value={trailer_type ?? undefined}
      onChange={toggleTrailerType}
      formControlProps={{ margin: "dense", fullWidth: true, required: true }}
      items={ftlTrailerTypeList}
      getItemCopy={(ftlTrailerType) => {
        switch (ftlTrailerType) {
          case FtlTrailerType.Rgn:
            return "Removable Gooseneck (RGN)";
          case FtlTrailerType.NaPartnersChoice:
            return "N/A - Partner's Choice";
          default: {
            return startCase(capitalize(ftlTrailerType));
          }
        }
      }}
    />
  );

  const defaultTrailerSizeField = (
    <FormControl required margin="dense" fullWidth>
      <FormLabel>{t("shipper:loadAttributesStep.trailerSize")}</FormLabel>
      <ButtonGroup color={"primary"} state={trailer_size ? undefined : "error"} fullWidth>
        {Object.values(TrailerSizeEnum)
          .filter((type) => !type.startsWith("C_"))
          .map((trailerSize) => (
            <Button
              key={trailerSize}
              className={trailer_size && `${trailer_size}` === trailerSize ? "Ptx-selected" : ""}
              onClick={() => onTrailerSizeChange({ trailer_size: trailerSize as unknown as TrailerSize })}
            >
              {t(`common:trailerSizes.${trailerSize}`)}
            </Button>
          ))}
      </ButtonGroup>
    </FormControl>
  );

  const notApplicableSizeField = (
    <FormControl required margin="dense" fullWidth>
      <FormLabel>{t("shipper:loadAttributesStep.trailerSize")}</FormLabel>
      <ButtonGroup color={"primary"} state={trailer_size ? undefined : "error"} fullWidth>
        <Button
          className={trailer_size === TrailerSize.Na ? "Ptx-selected" : ""}
          onClick={() => onTrailerSizeChange({ trailer_size: TrailerSize.Na })}
        >
          {t("shipper:n/a")}
        </Button>
      </ButtonGroup>
    </FormControl>
  );

  const boxTruckSizeField = (
    <FormControl required margin="dense" fullWidth>
      <FormLabel>{t("shipper:loadAttributesStep.trailerSize")}</FormLabel>
      <ButtonGroup color={"primary"} state={trailer_size ? undefined : "error"} fullWidth>
        <Button
          className={trailer_size === TrailerSize.T_26 ? "Ptx-selected" : ""}
          onClick={() => onTrailerSizeChange({ trailer_size: TrailerSize.T_26 })}
        >
          26’
        </Button>
        <Button
          className={trailer_size === TrailerSize.Na ? "Ptx-selected" : ""}
          onClick={() => onTrailerSizeChange({ trailer_size: TrailerSize.Na })}
        >
          {t("shipper:n/a")}
        </Button>
      </ButtonGroup>
    </FormControl>
  );

  const drayageSizeField = (
    <FormControl required margin="dense" fullWidth>
      <FormLabel>{t("shipper:loadAttributesStep.trailerSize")}</FormLabel>
      <ButtonGroup color={"primary"} state={trailer_size ? undefined : "error"} fullWidth>
        {Object.values(TrailerSizeEnum)
          .filter((size) => size.startsWith("C_"))
          .map((trailerSize) => (
            <Button
              key={trailerSize}
              className={trailer_size && `${trailer_size}` === trailerSize ? "Ptx-selected" : ""}
              onClick={() => onTrailerSizeChange({ trailer_size: trailerSize as unknown as TrailerSize })}
            >
              {t(`common:trailerSizes.${trailerSize}`)}
            </Button>
          ))}
      </ButtonGroup>
    </FormControl>
  );

  const getTrailerTypeField = (trailerType: FtlTrailerType): ReactElement => {
    if (trailerType === FtlTrailerType.SprinterVan || trailerType === FtlTrailerType.Rgn) {
      return notApplicableSizeField;
    } else if (trailerType === FtlTrailerType.BoxTruck) {
      return boxTruckSizeField;
    } else if (trailerType === FtlTrailerType.Drayage || trailerType === FtlTrailerType.DrayageReefer) {
      return drayageSizeField;
    } else {
      return defaultTrailerSizeField;
    }
  };

  const minTempField = (
    <FormControl fullWidth margin="dense">
      <FormLabel>{t("shipper:minTemp")}</FormLabel>
      <TemperatureInput value={min_temp ?? ""} onChange={onMinTempChange} />
    </FormControl>
  );

  const maxTempField = (
    <FormControl fullWidth margin="dense">
      <FormLabel>{t("shipper:maxTemp")}</FormLabel>
      <TemperatureInput value={max_temp ?? ""} onChange={onMaxTempChange} />
    </FormControl>
  );

  const palletTypeField = (
    <FormSelectView
      label={t("shipper:palletType_label")}
      highlight={!pallet_type}
      value={pallet_type ?? undefined}
      onChange={togglePalletType}
      formControlProps={{ margin: "dense", fullWidth: true, required: true }}
      items={palletTypeList}
      getItemCopy={(palletType) => t(`shipper:palletTypeMap.${palletType}` as ResourceKey)}
    />
  );

  const palletCountField = (
    <NumberInput
      translate="no"
      label={t("shipper:palletCount")}
      value={pallet_count ?? undefined}
      margin="dense"
      fullWidth
      onlyValidInput
      disableError
      validator="PositiveInt"
      onChange={onPalletCountChange}
    />
  );

  const packagingTypeField = (
    <FormSelectView
      onClear={handleClearPackagingType}
      onChange={togglePackagingType}
      label={t("shipper:packingType")}
      value={packaging_type ?? undefined}
      placeholder={t("shipper:setPackingType")}
      formControlProps={{ margin: "dense", fullWidth: true }}
      /** @todo: Remove the filter once ExtraPalletsType and "PALLETS" entry in PackagingType are removed */
      items={packagingTypeList.filter((t) => !t.startsWith("PALLETS"))}
      getItemCopy={(packagingType) => t(`shipper:packingTypeMap.${packagingType}` as ResourceKey)}
    />
  );

  const packagingCountField = (
    <NumberInput
      translate="no"
      label={t(`shipper:packingCountLabelMap.${packaging_type || "GENERIC"}` as ResourceKey)}
      value={packing_count ?? undefined}
      margin="dense"
      fullWidth
      onlyValidInput
      disableError
      validator="PositiveInt"
      onChange={onPackingCountChange}
    />
  );

  const unitCountField = (
    <NumberInput
      translate="no"
      label={t("shipper:unitCount")}
      value={unit_count ?? undefined}
      margin="dense"
      fullWidth
      onlyValidInput
      disableError
      validator="PositiveInt"
      onChange={onUnitCountChange}
    />
  );

  const commoditiesField = (
    <FormStringConcat
      margin="dense"
      label={t("common:commodities")}
      onChange={handleCommoditiesChange}
      value={commodities ?? ""}
    />
  );

  const hazardousNotesField = (
    <TextInput
      margin="dense"
      multiline
      translate="no"
      rows={3}
      label={t("shipper:shipmentDetailsStep.hazardousDetailsLabel")}
      placeholder={t("shipper:shipmentDetailsStep.hazardousDetailsPlaceholder")}
      fullWidth
      value={hazardous_goods_details ?? ""}
      onChange={onHazardousNotesChange}
      required
      highlight={!hazardous_goods_details}
    />
  );

  const noteField = (
    <ThrottledTextInput
      multiline
      translate="no"
      rows={3}
      label={t("common:additionalNotes")}
      placeholder={t("shipper:additionalNotesPlaceholder")}
      InputProps={{ style: { paddingTop: 0, paddingBottom: 0 } }}
      fullWidth
      value={deserializeNotes(note)}
      onChange={onNoteChange}
    />
  );

  const { totalDistance } = getQuoteRequestInfo(quoteRequest);

  const goodsValueField = (
    <FloatInput
      label={t("common:goodsValueOptional")}
      startIcon={<Box ml={1}>$</Box>}
      value={goods_value ?? 0}
      placeholder={t("common:goodsValue")}
      decimalPlace={2}
      onChange={handleChangeGoodsValue}
    />
  );

  const backDisabled = loading;
  const nextDisabled =
    !trailer_type ||
    !trailer_size ||
    updatingFtlLoadSpec ||
    updatingQuoteRequest ||
    (!!is_hazardous && !hazardous_goods_details) ||
    (!!is_palletized && !pallet_type);

  return (
    <LayoutColumnTwo.Content
      noHeadBorder
      active={active}
      loading={loading}
      backProps={{
        disabled: backDisabled,
        onClick: async () => {
          const success = await handleBackOrNext();
          onGoToStep(success, prevStep);
        },
      }}
      nextProps={{
        disabled: nextDisabled,
        onClick: async () => {
          const success = await handleBackOrNext();
          onGoToStep(success, nextStep);
        },
      }}
    >
      <Box display="flex" flexDirection="column" height="100%" overflow="auto">
        <Box pt={5} pb={5}>
          <StepTitle
            title={t("shipper:loadAttributes")}
            subtitle={t("shipper:shipmentDetailsStep.shipmentDetailsSubTitle")}
          />
        </Box>

        <Section title={t("shipper:shipmentDetailsStep.referenceNoUrgency")} variant="grey">
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <TextInput
                fullWidth
                label={
                  <Typography>
                    <Trans i18nKey="shipmentDetailsStep.referenceNoOptional" ns="shipper">
                      <strong>PO/Reference No. </strong>(optional)
                    </Trans>
                  </Typography>
                }
                margin="dense"
                value={reference_number || ""}
                placeholder={t("shipper:shipmentDetailsStep.setReferenceNo")}
                onChange={onReferenceNumberChange}
              />
            </Grid>
            <Grid item sm={6}>
              <FormControl fullWidth margin={"dense"}>
                <FormLabel>{t("shipper:urgency")}</FormLabel>
                <QuoteUrgency
                  className={classes.urgency}
                  data={urgencyOptions}
                  getLabel={renderUrgencyLabel}
                  value={selectedUrgencyOption?.id}
                  onChange={handleChangeUrgency}
                  onClear={() => handleClearUrgency()}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Section>

        <Section title={t("shipper:loadAttributesStep.trucksAndTrailers")}>
          <Grid container spacing={2}>
            <Grid item sm={3}>
              {truckQuantityField}
            </Grid>
            <Grid item sm={3}>
              <DriverPreferenceView
                driverPreference={driver_preference ?? DriverPreference.None}
                onSelectDriverPreference={onSelectDriverPreference}
              />
            </Grid>
            <Grid item sm={3}>
              {trailerTypeField}
            </Grid>
            <Grid item sm={3}>
              {getTrailerTypeField(trailer_type ?? FtlTrailerType.DryVan)}
            </Grid>
            {trailer_type === FtlTrailerType.Reefer ||
            trailer_type == FtlTrailerType.FoodGradeTank ||
            trailer_type === FtlTrailerType.DrayageReefer ? (
              <>
                <Grid item sm={3}>
                  {minTempField}
                </Grid>
                <Grid item sm={3}>
                  {maxTempField}
                </Grid>
              </>
            ) : null}
          </Grid>
        </Section>

        <Section title={t("shipper:loadAttributesStep.packingInformation")} variant="grey">
          <Grid container spacing={2}>
            <Grid item sm={6} lg={4}>
              {packingMethodField}
            </Grid>

            {is_palletized ? (
              <>
                <Grid item sm={3}>
                  {palletTypeField}
                </Grid>
                <Grid item sm={3}>
                  {palletCountField}
                </Grid>
                <Grid item sm={4}>
                  {packagingTypeField}
                </Grid>
                <Grid item sm={4}>
                  {packagingCountField}
                </Grid>
                <Grid item sm={4}>
                  {unitCountField}
                </Grid>
              </>
            ) : (
              <>
                <Grid item sm={6} lg={3}>
                  {packagingTypeField}
                </Grid>
                <Grid item sm={6} lg={3}>
                  {packagingCountField}
                </Grid>
                <Grid item sm={6} lg={2}>
                  {unitCountField}
                </Grid>
              </>
            )}
          </Grid>
        </Section>

        <Section title={t("shipper:loadAttributesStep.unitsAndWeights")}>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              {totalWeightField}
            </Grid>
            <Grid item sm={4}>
              {weightPerUnitField}
            </Grid>
            <Grid item sm={4}>
              {weightUnitField}
            </Grid>
          </Grid>
        </Section>

        <Section title={upperCase(t("common:commodities"))} variant="grey">
          <Grid container spacing={2}>
            <Grid item sm={6}>
              {commoditiesField}
            </Grid>
            <Grid item sm={6}>
              {hazardousToggleField}
            </Grid>
            {is_hazardous ? (
              <Grid item sm={6}>
                {hazardousNotesField}
              </Grid>
            ) : null}
            <Grid item sm={12}>
              {goodsValueField}
            </Grid>
          </Grid>
        </Section>

        <Box px={3} py={3} bgcolor="grey.50">
          {noteField}
        </Box>
        <Box px={3} py={3} bgcolor="grey.50">
          <StopsWithGoogleDistance.Stops stops={quoteRequest?.stops} totalDistance={totalDistance} />
        </Box>
      </Box>
    </LayoutColumnTwo.Content>
  );
};

export default LoadAttributesStep;
