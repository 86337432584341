import { VFC } from "react";

import { ChatBubbleOutlineOutlined } from "@material-ui/icons";
import { Button, TextInput } from "@portex-pro/ui-components";
import PortexDialog, { PortexDialogProps } from "components/PortexDialog";
import { useTranslation } from "react-i18next";

type ShareDocsDialogViewProps = {
  dialogProps: PortexDialogProps;
  documentsSelectedCount: number;
  onClickShareInChat?: () => void;
  onClickSendEmail?: () => void;
  onChangeEmail?: (value: string) => void;
};

const ShareDocsDialogView: VFC<ShareDocsDialogViewProps> = ({
  dialogProps,
  documentsSelectedCount,
  onClickShareInChat,
  onClickSendEmail,
  onChangeEmail,
}) => {
  const { t } = useTranslation("shipments");

  return (
    <PortexDialog
      title={t("documents_share_dialog_title", { count: documentsSelectedCount })}
      hideDialogActions
      {...dialogProps}
    >
      <div className="flex flex-col gap-2 p-4 pt-6">
        <div className="flex justify-between items-center">
          <span>{t("documents_share_in_chat_instructions")}</span>

          <Button
            startIcon={<ChatBubbleOutlineOutlined />}
            color="primary"
            variant="contained"
            style={{ paddingLeft: 16, paddingRight: 16 }}
            onClick={onClickShareInChat}
          >
            {t("documents_share_in_chat_button")}
          </Button>
        </div>

        <div className="py-4 flex items-center justify-center w-full">
          <div className="flex-1 border-t border-gray-300"></div>
          <span className="mx-4 text-gray-500">{t("documents_share_or")}</span>
          <div className="flex-1 border-t border-gray-300"></div>
        </div>

        <div className="flex flex-col gap-6">
          <div className="w-full">{t("documents_share_send_email_instructions")}</div>

          <div className="flex justify-between items-end space-x-5">
            <TextInput
              fullWidth
              label={t("documents_share_send_email_label")}
              placeholder={t("documents_share_send_email_placeholder")}
              onChange={(event) => onChangeEmail?.(event.target.value)}
            />
            <Button color="primary" variant="contained" style={{ minWidth: "max-content" }} onClick={onClickSendEmail}>
              {t("documents_share_send_email_button")}
            </Button>
          </div>
        </div>
      </div>
    </PortexDialog>
  );
};

export default ShareDocsDialogView;
