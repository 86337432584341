import { useState, VFC } from "react";

import { useUpdateShipmentMutation } from "api/rest/shipments/shipmentsApi";
import { useNotificationWatcher } from "hooks/notifications/useNotificationWatcher";
import useLDFlag from "hooks/useLDFlag";

import FilesControl, {
  OnUploadSuccessCallback,
  useFilesContext,
} from "../../../../components/file-uploads/FilesControl";
import { useShipmentsSliceSelector } from "../store/shipmentsStore";
import { Shipment } from "../types/domain";
import ConfirmDeleteShipmentFileView from "./ConfirmDeleteShipmentFileView";
import DocumentsBulkActionsContainer from "./documents/containers/DocumentsBulkActionsContainer";
import ShipmentDocumentsTabView from "./ShipmentDocumentsTabView";

interface ShipmentDocumentsTabContainerProps {
  shipment: Shipment;
}

const ShipmentDocumentsTabInnerContainer: VFC = () => {
  const { useGetFiles, onDeleteFileFromDB } = useFilesContext();
  const files = useGetFiles();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [fileDeleteRequestedId, setFileDeleteRequestedId] = useState<string | null>(null);
  const enableShareDocuments = useLDFlag("enableShareDocuments");
  const { selectAllState } = useShipmentsSliceSelector((state) => state.shipmentDocumentsSlice);

  const handleDeleteRequest = (id: string) => {
    setFileDeleteRequestedId(id);
    setDeleteConfirmationOpen(true);
  };
  const handleCloseCancelDialog = () => {
    setDeleteConfirmationOpen(false);
    setFileDeleteRequestedId(null);
  };

  const handleDeleteFile = async (id: string) => {
    await onDeleteFileFromDB(id);
    handleCloseCancelDialog();
  };
  return (
    <>
      <ConfirmDeleteShipmentFileView
        fileId={fileDeleteRequestedId}
        isOpen={deleteConfirmationOpen}
        handleClose={handleCloseCancelDialog}
        handleDelete={handleDeleteFile}
      />
      <DocumentsBulkActionsContainer />
      <ShipmentDocumentsTabView
        isFetching={!files}
        documents={files}
        handleDeleteRequest={handleDeleteRequest}
        enableShareDocuments={enableShareDocuments}
        headerCheckboxState={selectAllState}
        onClickHeaderCheckbox={() => alert("@todo: implement me")}
        onClickRowCheckbox={() => alert("@todo: implement me")}
        isChecked={() => false}
      />
    </>
  );
};

const ShipmentDocumentsTabContainer: VFC<ShipmentDocumentsTabContainerProps> = ({ shipment }) => {
  const [fileIds, setFileIds] = useState<string[]>(shipment.fileIds);
  const [updateShipment] = useUpdateShipmentMutation();
  useNotificationWatcher([`bolGenerationComplete:${shipment.id}`]);

  const handleUploadFileSuccess: OnUploadSuccessCallback = async ({ fileId }) => {
    const newFileIds = [...fileIds, fileId];

    setFileIds(newFileIds);
    updateShipment({
      urlParams: { shipmentId: shipment.id },
      body: { fileIds: newFileIds, versionNumber: shipment.versionNumber },
    });
  };

  const handleDeleteFileSuccess = async (fileId: string) => {
    setFileIds((prev) => prev.filter((value) => value !== fileId));
  };

  return (
    <FilesControl
      fileIds={fileIds}
      onUploadSuccess={handleUploadFileSuccess}
      onDeleteSuccess={handleDeleteFileSuccess}
      dropzoneOptions={{ maxFiles: 4, multiple: false, maxSize: 5e6 }}
    >
      <ShipmentDocumentsTabInnerContainer />
    </FilesControl>
  );
};

export default ShipmentDocumentsTabContainer;
