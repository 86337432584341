import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import usableActions from "utils/store/usableActions";

type State = {
  selectAllState: "empty" | "indeterminate" | "allSelected";
  selectedDocuments: unknown[]; // implement me
  isShareDialogOpen: boolean;
};

const initialState: State = {
  selectedDocuments: [],
  selectAllState: "empty",
  isShareDialogOpen: false,
};

const slice = createSlice({
  name: "shipmentDocumentsSlice",
  initialState,
  reducers: {
    setIsShareDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isShareDialogOpen = action.payload;
    },
  },
});

export const { useSetIsShareDialogOpen } = usableActions(slice.actions);
export default slice;
