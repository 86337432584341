import { useMemo, VFC } from "react";

import { LocationOnOutlined } from "@material-ui/icons";
import { ShipmentStop } from "app/pages/shipments/types/domain";
import formatAddress from "app/pages/shipments/utils/formatAddress";
import Text from "components/Text";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import { useTranslation } from "react-i18next";

interface StopSelectViewProps {
  selectedStopId: ShipmentStop["id"];
  stops: ShipmentStop[];
  onChange: (stopId: ShipmentStop["id"]) => void;
  addressFormat?: "long" | "short";
}

const StopSelectView: VFC<StopSelectViewProps> = (props) => {
  const { selectedStopId, stops, onChange, addressFormat = "long" } = props;
  const { t } = useTranslation("bolGeneration");

  const stopIds = useMemo(() => stops.map((stop) => stop.id), [stops]);

  return (
    <FormSelectView
      items={stopIds}
      getItemCopy={(stopId) => {
        const stop = stops.find((stop) => stop.id === stopId);
        const stopIndex = stops.findIndex((stop) => stop.id === stopId);

        if (!stop) {
          return "";
        }

        const addressString = formatAddress(stop.address, addressFormat);

        return (
          <div className="w-full flex flex-row justify-between items-center">
            <div className="flex flex-row items-center space-x-2">
              <LocationOnOutlined />
              <Text size="small">{addressString}</Text>
            </div>
            <Text
              size="x-small"
              weight="bold"
              typographyProps={{ color: "textSecondary", style: { textTransform: "uppercase", marginLeft: "16px" } }}
            >
              {t("stop", {
                context: stopIndex === 0 ? "pickup" : stopIndex === stops.length - 1 ? "delivery" : stopIndex,
              })}
            </Text>
          </div>
        );
      }}
      value={selectedStopId}
      onChange={onChange}
    />
  );
};

export default StopSelectView;
