export enum TrailerTypeEnum {
  DRY_VAN = "DRY_VAN",
  FLATBED = "FLATBED",
  REEFER = "REEFER",
  FOOD_GRADE_TANK = "FOOD_GRADE_TANK",
  BULK = "BULK",
  STEP_DECK = "STEP_DECK",
  BOX_TRUCK = "BOX_TRUCK",
  SPRINTER_VAN = "SPRINTER_VAN",
  CONESTOGA = "CONESTOGA",
  HOTSHOT = "HOTSHOT",
  RGN = "RGN",
  DRAYAGE = "DRAYAGE",
  DRAYAGE_REEFER = "DRAYAGE_REEFER",
  CARGO_VAN = "CARGO_VAN",
  DUMP = "DUMP",
  PARTIAL_LOAD = "PARTIAL_LOAD",
  NA_PARTNERS_CHOICE = "NA_PARTNERS_CHOICE",
  OTHER = "OTHER",
}

export type TrailerType = `${TrailerTypeEnum}`;
