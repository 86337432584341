import { VFC } from "react";

import { Checkbox, FormControl, FormControlLabel, InputLabel, TextField } from "@portex-pro/ui-components";
import Text from "components/Text";
import { FormData, FreightTerms } from "features/generate-bol/store/generateBOLSlice";
import { useTranslation } from "react-i18next";

interface ThirdPartyFreightViewProps {
  thirdPartyBillTo?: string;
  freightTerms?: FreightTerms;

  onChange: (data: Partial<FormData>) => void;
}

const ThirdPartyFreightView: VFC<ThirdPartyFreightViewProps> = (props) => {
  const { onChange, freightTerms, thirdPartyBillTo } = props;
  const { t } = useTranslation("bolGeneration");

  return (
    <div className="flex flex-col w-full bg-white">
      <div className="w-full py-2.5 px-5 bg-grey-100 border-b border-t border-grey-300">
        <Text
          size="medium"
          weight="bold"
          typographyProps={{ style: { textAlign: "center", textTransform: "uppercase" } }}
        >
          {t("thirdPartyFreight")}
        </Text>
      </div>
      <div className="p-5 flex flex-col space-y-5">
        <FormControl fullWidth>
          <InputLabel>{t("thirdPartyFreight_description")}</InputLabel>
          <TextField
            value={thirdPartyBillTo}
            multiline
            rows={4}
            onChange={(event) => onChange({ carrier: event.target.value })}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel style={{ marginBottom: 0 }}>{t("freightTerms")}</InputLabel>
          <div>
            <FormControlLabel
              control={
                <Checkbox checked={freightTerms === "Prepaid"} onClick={() => onChange({ freightTerms: "Prepaid" })} />
              }
              label={t("prepaid")}
            />
            <FormControlLabel
              control={
                <Checkbox checked={freightTerms === "Collect"} onClick={() => onChange({ freightTerms: "Collect" })} />
              }
              label={t("collect")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={freightTerms === "3rd Party"}
                  onClick={() => onChange({ freightTerms: "3rd Party" })}
                />
              }
              label={t("thirdParty")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={freightTerms === "Master BOL"}
                  onClick={() => onChange({ freightTerms: "Master BOL" })}
                />
              }
              label={t("masterBOL")}
            />
          </div>
        </FormControl>
        <Text size="medium" typographyProps={{ style: { marginTop: "0.25rem" } }}>
          {t("thirdPartyFreight_disclaimer")}
        </Text>
      </div>
    </div>
  );
};

export default ThirdPartyFreightView;
