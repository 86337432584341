import { VFC } from "react";

import { Button } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import ShareArrowSvg from "./ShareIconView";

type DocumentsBulkActionsViewProps = {
  documentsSelectedCount: number;
  onClickShare?: () => void;
};

const DocumentsBulkActionsView: VFC<DocumentsBulkActionsViewProps> = ({ documentsSelectedCount, onClickShare }) => {
  const { t } = useTranslation("shipments");

  return (
    <div className="p-3 bg-white flex justify-end items-center space-x-3">
      <span className="font-bold">{t("documents_selected_count", { count: documentsSelectedCount })}</span>
      <Button
        startIcon={<ShareArrowSvg />}
        color="primary"
        variant="outlined"
        style={{ paddingLeft: 16, paddingRight: 16 }}
        onClick={onClickShare}
      >
        Share
      </Button>
    </div>
  );
};

export default DocumentsBulkActionsView;
