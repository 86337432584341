import { VFC } from "react";

import { FormControl, InputLabel, TextField } from "@portex-pro/ui-components";
import Text from "components/Text";
import { FormData } from "features/generate-bol/store/generateBOLSlice";
import { useTranslation } from "react-i18next";

interface SpecialInstructionsViewProps {
  specialInstructions?: string;
  shipFromAccessorials?: string;
  shipToAccessorials?: string;

  onChange: (data: Partial<FormData>) => void;
}

const SpecialInstructionsView: VFC<SpecialInstructionsViewProps> = (props) => {
  const { onChange, shipFromAccessorials, shipToAccessorials, specialInstructions } = props;
  const { t } = useTranslation("bolGeneration");

  return (
    <div className="flex flex-col w-full bg-white">
      <div className="w-full py-2.5 px-5 bg-grey-100 border-b border-t border-grey-300">
        <Text
          size="medium"
          weight="bold"
          typographyProps={{ style: { textAlign: "center", textTransform: "uppercase" } }}
        >
          {t("specialInstructions")}
        </Text>
      </div>
      <div className="p-5 flex flex-col space-y-5">
        <FormControl fullWidth>
          <InputLabel>{t("specialInstructions")}</InputLabel>
          <TextField
            multiline
            rows={4}
            placeholder={t("specialInstructions_placeholder")}
            value={specialInstructions}
            onChange={(event) => onChange({ specialInstructions: event.target.value })}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("shipFromAccessorials")}</InputLabel>
          <TextField
            multiline
            rows={4}
            value={shipFromAccessorials}
            onChange={(event) => onChange({ shipFromAccessorials: event.target.value })}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("shipToAccessorials")}</InputLabel>
          <TextField
            multiline
            rows={4}
            value={shipToAccessorials}
            onChange={(event) => onChange({ shipToAccessorials: event.target.value })}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default SpecialInstructionsView;
