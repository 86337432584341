import { VFC } from "react";

import { useCreateBillOfLadingMutation } from "api/rest/bill-of-lading/createBillOfLadingApi";
import { Shipment } from "app/pages/shipments/types/domain";
import formatAddress from "app/pages/shipments/utils/formatAddress";
import uniqueId from "lodash/uniqueId";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import { useEffectOnce } from "usehooks-ts";
import { useAddListenerFileDownloadReady } from "utils/store/configureStore";
import BolGenerateView from "views/generate-bol/BolGenerateView";

import {
  useChangeCargoDetail,
  useChangeFormData,
  useChangeLoad,
  useChangeShipLocation,
  useChangeStop,
  useResetState,
} from "./store/generateBOLSlice";
import { useGenerateBOLSlices, useGenerateBOLSliceSelector } from "./store/generateBOLStore";
import { mapStateToApiRequest } from "./utils";

interface GenerateBOLShipperProps {
  shipment: Shipment;
}

const GenerateBOLShipper: VFC<GenerateBOLShipperProps> = (props) => {
  const { shipment: providedShipment } = props;
  useGenerateBOLSlices();
  const { shipment: storedShipment, formData, loadId } = useGenerateBOLSliceSelector((state) => state.generateBOLSlice);
  const resetState = useResetState();
  const changeFormData = useChangeFormData();
  const changeStop = useChangeStop();
  const changeShipLocation = useChangeShipLocation();
  const changeCargoDetail = useChangeCargoDetail();
  const changeLoad = useChangeLoad();
  const [generateBillOfLading, { isLoading }] = useCreateBillOfLadingMutation();
  const { t } = useTranslation("bolGeneration");
  const { enqueueSnackbar } = useSnackbar();
  const addListenerFileDownloadReady = useAddListenerFileDownloadReady();

  let shipment = storedShipment;
  const loadIndex = shipment?.trucks?.findIndex((truck) => truck.id === loadId);
  const loadIds = shipment?.trucks?.map((truck) => truck.id ?? 0) ?? [];

  useEffectOnce(() => {
    if (!storedShipment) {
      resetState(providedShipment);
      shipment = providedShipment;
      return;
    }

    if (storedShipment.id !== providedShipment.id) {
      resetState(providedShipment);
      shipment = providedShipment;
      return;
    }
  });

  const generateBOL = async () => {
    try {
      if (!shipment || !formData || loadIndex === undefined) {
        throw new Error("Attempted to generate bol without shipment or formdata or loadIndex");
      }

      const fileName = t("fileName_format", {
        loadNumber: loadIndex + 1,
        shipFrom: formatAddress(
          shipment?.stops.find((stop) => stop.id === formData?.shipFrom.stopId)?.address,
          "medium"
        ),
        shipTo: formatAddress(shipment?.stops.find((stop) => stop.id === formData?.shipTo.stopId)?.address, "medium"),
      });

      // random uuid, fallback to a unique id from lodash if crypto doesn't work
      const fileDownloadUUID =
        crypto?.randomUUID?.() ?? uniqueId((shipment.id.toString() ?? "").concat(DateTime.now().toISO()));

      await generateBillOfLading({
        body: {
          shipmentId: shipment.id.toString(),
          formData: mapStateToApiRequest({ shipment, formData }),
          fileName,
          fileDownloadUUID,
        },
      }).unwrap();
      addListenerFileDownloadReady(fileDownloadUUID);
      enqueueSnackbar(t("generate_success"), { variant: "success" });
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(t("generate_error"), { variant: "error" });
    }
  };

  if (!formData || loadIndex === undefined || loadId === undefined) {
    return null;
  }

  return (
    <BolGenerateView
      loadNumber={loadIndex + 1}
      selectedLoadId={loadId}
      loadIds={loadIds}
      onChangeLoad={changeLoad}
      onGenerate={generateBOL}
      disableGenerate={isLoading}
      formData={formData}
      stops={shipment?.stops ?? []}
      onChange={changeFormData}
      onChangeStop={(stopId, location) => changeStop({ stopId, location })}
      onChangeShipLocation={(data, location) => changeShipLocation({ data, location })}
      onChangeCargoDetail={(data, index) => changeCargoDetail({ data, index })}
      onAddAnotherItem={() => changeFormData({ cargo: [...(formData.cargo ?? []), {}] })}
    />
  );
};

export default GenerateBOLShipper;
