import { VFC } from "react";

import { Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { BaseShipment } from "../types/domain";
import formatEquipment from "../utils/formatEquipment";

const EquipmentCellView: VFC<{ shipment: BaseShipment }> = ({ shipment }) => {
  const { t } = useTranslation(["shipper", "common"]);

  return <Typography>{formatEquipment(t, shipment)}</Typography>;
};

export default EquipmentCellView;
