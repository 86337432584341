import { ComponentProps, VFC } from "react";

import NotificationBadge from "components/NotificationBadge";

import { useGetShipperLoads } from "../hooks/useGetShipperLoads";
import ShipperLoadsIndexTableView from "../views/ShipperLoadsIndexTableView";

const ShipperLoadsIndexTableContainer: VFC = () => {
  const shipperLoads = useGetShipperLoads();

  const handleGetLoadUrl: ComponentProps<typeof ShipperLoadsIndexTableView>["onGetLoadUrl"] = (load) => {
    return `/shipper/shipments/${load.shipment_id}/activity?loadId=${load.id}`;
  };

  return (
    <ShipperLoadsIndexTableView
      loads={shipperLoads.data?.data ?? []}
      paginationOptions={shipperLoads.paginationOptions}
      onGetLoadUrl={handleGetLoadUrl}
      isLoading={shipperLoads.isLoading || shipperLoads.isFetching}
      LoadButtonWrapper={({ load, children }) => (
        <NotificationBadge
          position="topLeft"
          identifiers={[`shipment:${load.shipment_id}`, `bolGenerationComplete:${load.shipment_id}`]}
        >
          {children}
        </NotificationBadge>
      )}
    />
  );
};

export default ShipperLoadsIndexTableContainer;
