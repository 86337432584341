import { TFunction } from "react-i18next";
import { TrailerTypeEnum } from "types/TrailerType";

import { BaseShipment, Shipment } from "../types/domain";

interface FormatOptions {
  shortQuantity?: boolean;
}

/** @depcrated Let's stop using this function. Let's rewrite this to be cleaner + safer usage of i18n */
function formatEquipment(
  t: TFunction<["shipper" | "common"]>,
  shipment: BaseShipment | Shipment,
  { shortQuantity = false }: FormatOptions = {}
): string {
  if (!shipment.loadSpec) return "";
  const { minTemp, maxTemp, trailerSize, trailerType } = shipment.loadSpec;
  const truckQuantity = shipment.trucks?.length || 0;

  const formattedTrailerSize = !!trailerSize && t(`common:trailerSizes.${trailerSize}`);
  const formattedTrailerType = !!trailerType && t(`common:trailerTypes.${trailerType}`);

  const maybeMinTemp = typeof minTemp === "number" && `${minTemp}˚F`;
  const maybeMinMaxTemp = typeof maxTemp === "number" && `${maxTemp}˚F`;

  /** @todo: consider changing "to" to something more universal, like a dash, for i18 reasons. */
  const maybeTemps =
    trailerType === (TrailerTypeEnum.REEFER || TrailerTypeEnum.FOOD_GRADE_TANK || TrailerTypeEnum.DRAYAGE_REEFER) &&
    (minTemp || maxTemp)
      ? `(${[maybeMinTemp, maybeMinMaxTemp].filter(Boolean).join(" to ")})`
      : "";

  /** @todo: add i18n; note the plurals */
  const formattedTruckQuantity = truckQuantity
    ? shortQuantity
      ? `${truckQuantity} x`
      : `${truckQuantity} Load${truckQuantity > 1 ? "s" : ""}`
    : "";

  return [
    [formattedTruckQuantity].filter(Boolean).join(""),
    [formattedTrailerSize, formattedTrailerType, maybeTemps].filter(Boolean).join(" "),
  ]
    .flat()
    .filter(Boolean)
    .join(" - ");
}

export default formatEquipment;
