import {
  BaseShipment,
  DriverPreference,
  PublicShipment,
  Shipment,
  ShipmentAccessorial,
  ShipmentAccessorialType,
  ShipmentAddress,
  ShipmentAddressType,
  ShipmentBookedQuote,
  ShipmentContact,
  ShipmentDimUnit,
  ShipmentFreightClass,
  ShipmentLoadSpec,
  ShipmentMode,
  ShipmentOwner,
  ShipmentPackageGroup,
  ShipmentPackagingType,
  ShipmentPalletType,
  ShipmentQuoteDetails,
  ShipmentSource,
  ShipmentState,
  ShipmentStop,
  ShipmentTruck,
  ShipmentWeightUnit,
} from "app/pages/shipments/types/domain";
import { MappingFunction } from "app/pages/shipments/types/utility";
import reverseDictionary from "app/pages/shipments/utils/reverseDictionary";
import first from "lodash/first";
import orderBy from "lodash/orderBy";

import { ApiResponse } from "../types/responses";
import {
  BrokerShipment,
  ListAccessorialOptionsApi,
  ListShipmentQuotesApi,
  ListShipmentsApi,
  RawAddress,
  RawAddressType,
  RawBaseShipment,
  RawBookedQuote,
  RawBrokerShipment,
  RawDriverPreference,
  RawLoadSpec,
  RawPackagingType,
  RawPalletType,
  RawPublicShipment,
  RawShipment,
  RawShipmentAccessorial,
  RawShipmentAccessorialType,
  RawShipmentContact,
  RawShipmentDimUnit,
  RawShipmentFreightClass,
  RawShipmentMode,
  RawShipmentOwner,
  RawShipmentPackageGroup,
  RawShipmentState,
  RawStop,
  RawTruck,
  RawWeightUnit,
  ShipmentDetailsApi,
  UpdateShipmentAddressPayload,
} from "./types";

export const transformNullableStringToEmptyString = (value: string | null | undefined): string | undefined => {
  if (value === null) {
    return "";
  }

  return value;
};

const shipmentStateDictionary: Record<RawShipmentState, ShipmentState> = {
  [RawShipmentState.Draft]: ShipmentState.Draft,
  [RawShipmentState.Canceled]: ShipmentState.Canceled,
  [RawShipmentState.Delivered]: ShipmentState.Delivered,
  [RawShipmentState.Created]: ShipmentState.Created,
  [RawShipmentState.InTransit]: ShipmentState.InTransit,
};

const reverseShipmentStateDictionary = reverseDictionary(shipmentStateDictionary);

export const mapToShipmentState: MappingFunction<RawShipmentState, ShipmentState> = (input) =>
  shipmentStateDictionary[input];

export const mapFromShipmentState: MappingFunction<ShipmentState, RawShipmentState> = (input) =>
  reverseShipmentStateDictionary[input];

const shipmentModeDictionary: Record<RawShipmentMode, ShipmentMode> = {
  [RawShipmentMode.Air]: ShipmentMode.Air,
  [RawShipmentMode.Drayage]: ShipmentMode.Drayage,
  [RawShipmentMode.Fcl]: ShipmentMode.Fcl,
  [RawShipmentMode.Ftl]: ShipmentMode.Ftl,
  [RawShipmentMode.Ltl]: ShipmentMode.Ltl,
  [RawShipmentMode.Lcl]: ShipmentMode.Lcl,
  [RawShipmentMode.Intermodal]: ShipmentMode.Intermodal,
};

export const mapToShipmentMode: MappingFunction<RawShipmentMode, ShipmentMode> = (input) =>
  shipmentModeDictionary[input];

const shipmentAddressTypeDictionary: Record<RawAddressType, ShipmentAddressType> = {
  [RawAddressType.Airport]: ShipmentAddressType.Airport,
  [RawAddressType.Street]: ShipmentAddressType.Street,
  [RawAddressType.Railhead]: ShipmentAddressType.Railhead,
  [RawAddressType.Seaport]: ShipmentAddressType.Seaport,
  [RawAddressType.Unknown]: ShipmentAddressType.Unknown,
};

const reverseShipmentAddressTypeDictionary = reverseDictionary(shipmentAddressTypeDictionary);

export const mapToShipmentAddressType: MappingFunction<RawAddressType, ShipmentAddressType> = (input) =>
  shipmentAddressTypeDictionary[input];

export const mapFromShipmentAddressType: MappingFunction<ShipmentAddressType, RawAddressType> = (input) =>
  reverseShipmentAddressTypeDictionary[input];

export const mapToShipmentContact: MappingFunction<RawShipmentContact, ShipmentContact> = ({
  first_name,
  last_name,
  phone_number,
  email,
}) => ({
  email: email || undefined,
  firstName: first_name || undefined,
  lastName: last_name || undefined,
  phoneNumber: phone_number || undefined,
});

export const mapFromShipmentContact: MappingFunction<ShipmentContact, RawShipmentContact> = ({
  email,
  firstName,
  lastName,
  phoneNumber,
}) => ({
  email,
  phone_number: phoneNumber,
  first_name: firstName,
  last_name: lastName,
});

export const mapToShipmentAddress: MappingFunction<RawAddress, ShipmentAddress> = ({
  id,
  address_1,
  address_2,
  address_contacts,
  city,
  country_code,
  country_name,
  google_place_description,
  google_place_id,
  hours_end,
  hours_start,
  iana_timezone,
  name,
  province_code,
  province_name,
  type,
  zip,
  booking_notes,
}) => ({
  id,
  address1: address_1 ?? undefined,
  address2: address_2 ?? undefined,
  name: name || undefined,
  city: city || "",
  countryCode: country_code || "",
  countryName: country_name || "",
  ianaTimezone: iana_timezone,
  provinceCode: province_code || undefined,
  provinceName: province_name || undefined,
  type: type ? mapToShipmentAddressType(type) : undefined,
  zip: zip || undefined,
  googlePlaceId: google_place_id || undefined,
  googlePlaceDescription: google_place_description || undefined,
  contact: address_contacts?.length
    ? mapToShipmentContact(first(orderBy(address_contacts, "is_primary_contact", ["desc"])) ?? {})
    : undefined,
  hoursStart: hours_start,
  hoursEnd: hours_end,
  bookingNotes: booking_notes,
});

export const mapFromShipmentAddress: MappingFunction<ShipmentAddress, RawAddress> = ({
  id,
  address1,
  address2,
  city,
  type,
  name,
  provinceName,
  countryName,
  countryCode,
  provinceCode,
  contact,
  zip,
  hoursEnd,
  hoursStart,
  ianaTimezone,
  googlePlaceId,
  googlePlaceDescription,
  optionType,
  bookingNotes,
}) => ({
  id,
  address_1: address1,
  address_2: address2,
  address_contacts: contact ? [mapFromShipmentContact(contact)] : [],
  city,
  country_code: countryCode,
  country_name: countryName,
  google_place_description: googlePlaceDescription,
  google_place_id: googlePlaceId,
  hours_end: hoursEnd ? hoursEnd : null,
  hours_start: hoursStart ? hoursStart : null,
  iana_timezone: ianaTimezone,
  name,
  province_code: provinceCode,
  province_name: provinceName,
  type: type ? mapFromShipmentAddressType(type) : undefined,
  zip,
  option_type: optionType,
  booking_notes: bookingNotes,
});

export const mapToShipmentStop = ({
  id,
  address,
  end,
  is_time_tbd,
  note,
  booking_notes,
  reference_number,
  start,
}: RawStop): ShipmentStop => ({
  id,
  address: address ? mapToShipmentAddress(address) : undefined,
  end,
  isTimeTbd: is_time_tbd,
  note,
  bookingNotes: booking_notes,
  referenceNumber: reference_number,
  start,
});

const driverPreferenceDictionary: Record<RawDriverPreference, DriverPreference> = {
  [RawDriverPreference.None]: DriverPreference.None,
  [RawDriverPreference.Solo]: DriverPreference.Solo,
  [RawDriverPreference.Team]: DriverPreference.Team,
};

const reverseDriverPreferenceDictionary = reverseDictionary(driverPreferenceDictionary);

const packagingTypeDictionary: Record<RawPackagingType, ShipmentPackagingType> = {
  [RawPackagingType.PALLETS_48_48]: ShipmentPackagingType.Pallets_48_40,
  [RawPackagingType.PALLETS_48_40]: ShipmentPackagingType.Pallets_48_40,
  [RawPackagingType.CARTONS]: ShipmentPackagingType.Cartons,
  [RawPackagingType.PALLETS]: ShipmentPackagingType.Pallets,
  [RawPackagingType.BAGS]: ShipmentPackagingType.Bags,
  [RawPackagingType.BALES]: ShipmentPackagingType.Bales,
  [RawPackagingType.BARRELS]: ShipmentPackagingType.Barrels,
  [RawPackagingType.BOXES]: ShipmentPackagingType.Boxes,
  [RawPackagingType.BUNDLES]: ShipmentPackagingType.Bundles,
  [RawPackagingType.COILS]: ShipmentPackagingType.Coils,
  [RawPackagingType.CONTAINERS]: ShipmentPackagingType.Containers,
  [RawPackagingType.CRATES]: ShipmentPackagingType.Crates,
  [RawPackagingType.ROLLS]: ShipmentPackagingType.Rolls,
  [RawPackagingType.SKIDS]: ShipmentPackagingType.Skids,
  [RawPackagingType.TOTES]: ShipmentPackagingType.Totes,
  [RawPackagingType.OTHER]: ShipmentPackagingType.Other,
};

const reversePackagingTypeDictionary = reverseDictionary(packagingTypeDictionary);

const palletTypeDictionary: Record<RawPalletType, ShipmentPalletType> = {
  [RawPalletType.PALLET_48_40]: ShipmentPalletType.Pallet_48_40,
  [RawPalletType.PALLET_42_42]: ShipmentPalletType.Pallet_42_42,
  [RawPalletType.PALLET_48_48]: ShipmentPalletType.Pallet_48_48,
  [RawPalletType.PALLET_40_48]: ShipmentPalletType.Pallet_40_48,
  [RawPalletType.PALLET_48_42]: ShipmentPalletType.Pallet_48_42,
  [RawPalletType.PALLET_40_40]: ShipmentPalletType.Pallet_40_40,
  [RawPalletType.PALLET_48_45]: ShipmentPalletType.Pallet_48_45,
  [RawPalletType.PALLET_44_44]: ShipmentPalletType.Pallet_44_44,
  [RawPalletType.PALLET_36_36]: ShipmentPalletType.Pallet_36_36,
  [RawPalletType.PALLET_48_36]: ShipmentPalletType.Pallet_48_36,
  [RawPalletType.PALLET_45_455]: ShipmentPalletType.Pallet_45_455,
  [RawPalletType.PALLET_48_20]: ShipmentPalletType.Pallet_48_20,
  [RawPalletType.PALLET_NOT_SPECIFIED]: ShipmentPalletType.PalletNotSpecified,
};

const reversePalletTypeDictionary = reverseDictionary(palletTypeDictionary);

const weightUnitDictionary: Record<RawWeightUnit, ShipmentWeightUnit> = {
  [RawWeightUnit.Kg]: ShipmentWeightUnit.Kg,
  [RawWeightUnit.Lb]: ShipmentWeightUnit.Lb,
};

const reverseWeightUnitDictionary: Record<ShipmentWeightUnit, RawWeightUnit> = reverseDictionary(weightUnitDictionary);

const shipmentAccessorialTypeDictionary: Record<RawShipmentAccessorialType, ShipmentAccessorialType> = {
  [RawShipmentAccessorialType.Delivery]: ShipmentAccessorialType.Delivery,
  [RawShipmentAccessorialType.Pickup]: ShipmentAccessorialType.Pickup,
};

const reverseAccessorialTypeDictionary = reverseDictionary(shipmentAccessorialTypeDictionary);

export const mapToShipmentTruck: MappingFunction<RawTruck, ShipmentTruck> = ({
  id,
  reference_number,
  tracking_link,
}) => ({
  id,
  referenceNumber: reference_number,
  trackingLink: tracking_link,
});

export const mapToShipmentAccessorial: MappingFunction<RawShipmentAccessorial, ShipmentAccessorial> = ({
  id,
  name,
  type,
}) => ({
  id,
  name,
  type: shipmentAccessorialTypeDictionary[type],
});

export const mapFromShipmentAccessorial: MappingFunction<ShipmentAccessorial, RawShipmentAccessorial> = ({
  id,
  name,
  type,
}) => ({
  id,
  name,
  type: reverseAccessorialTypeDictionary[type],
});

const dimUnitDictionary: Record<RawShipmentDimUnit, ShipmentDimUnit> = {
  [RawShipmentDimUnit.CM]: ShipmentDimUnit.CM,
  [RawShipmentDimUnit.IN]: ShipmentDimUnit.IN,
};

const reverseDimUnitDictionary = reverseDictionary(dimUnitDictionary);

const freightClassDictionary: Record<RawShipmentFreightClass, ShipmentFreightClass> = {
  [RawShipmentFreightClass.FC_50]: ShipmentFreightClass.FC_50,
  [RawShipmentFreightClass.FC_55]: ShipmentFreightClass.FC_55,
  [RawShipmentFreightClass.FC_60]: ShipmentFreightClass.FC_60,
  [RawShipmentFreightClass.FC_65]: ShipmentFreightClass.FC_65,
  [RawShipmentFreightClass.FC_70]: ShipmentFreightClass.FC_70,
  [RawShipmentFreightClass.FC_77_5]: ShipmentFreightClass.FC_77_5,
  [RawShipmentFreightClass.FC_85]: ShipmentFreightClass.FC_85,
  [RawShipmentFreightClass.FC_92_5]: ShipmentFreightClass.FC_92_5,
  [RawShipmentFreightClass.FC_100]: ShipmentFreightClass.FC_100,
  [RawShipmentFreightClass.FC_110]: ShipmentFreightClass.FC_110,
  [RawShipmentFreightClass.FC_125]: ShipmentFreightClass.FC_125,
  [RawShipmentFreightClass.FC_150]: ShipmentFreightClass.FC_150,
  [RawShipmentFreightClass.FC_175]: ShipmentFreightClass.FC_175,
  [RawShipmentFreightClass.FC_200]: ShipmentFreightClass.FC_200,
  [RawShipmentFreightClass.FC_250]: ShipmentFreightClass.FC_250,
  [RawShipmentFreightClass.FC_300]: ShipmentFreightClass.FC_300,
  [RawShipmentFreightClass.FC_400]: ShipmentFreightClass.FC_400,
  [RawShipmentFreightClass.FC_500]: ShipmentFreightClass.FC_500,
};

const reverseFreightClassDictionary = reverseDictionary(freightClassDictionary);

export const mapToShipmentPackageGroup: MappingFunction<RawShipmentPackageGroup, ShipmentPackageGroup> = ({
  commodities,
  dim_unit,
  freight_class,
  height_per_package,
  is_stackable,
  item_quantity,
  length_per_package,
  nmfc_code,
  packaging_type,
  weight_per_package,
  width_per_package,
}) => ({
  id: window.crypto.randomUUID(),
  commodities: commodities || undefined,
  dimUnit: dim_unit ? dimUnitDictionary[dim_unit] : undefined,
  freightClass: freight_class ? freightClassDictionary[freight_class] : undefined,
  heightPerPackage: height_per_package,
  isStackable: is_stackable || undefined,
  itemQuantity: item_quantity,
  lengthPerPackage: length_per_package,
  nmfcCode: nmfc_code || undefined,
  packagingType: packagingTypeDictionary[packaging_type],
  weightPerPackage: weight_per_package,
  widthPerPackage: width_per_package,
});

export const mapFromShipmentPackageGroup: MappingFunction<ShipmentPackageGroup, RawShipmentPackageGroup> = ({
  commodities,
  dimUnit,
  freightClass,
  heightPerPackage,
  isStackable,
  itemQuantity,
  lengthPerPackage,
  nmfcCode,
  packagingType,
  weightPerPackage,
  widthPerPackage,
}) => ({
  commodities,
  dim_unit: dimUnit ? reverseDimUnitDictionary[dimUnit] : undefined,
  freight_class: freightClass ? reverseFreightClassDictionary[freightClass] : undefined,
  height_per_package: heightPerPackage,
  is_stackable: isStackable,
  item_quantity: itemQuantity,
  length_per_package: lengthPerPackage,
  nmfc_code: nmfcCode,
  packaging_type: reversePackagingTypeDictionary[packagingType],
  weight_per_package: weightPerPackage,
  width_per_package: widthPerPackage,
});

export const mapToShipmentLoadSpec: MappingFunction<RawLoadSpec, ShipmentLoadSpec> = ({
  commodities,
  driver_preference,
  is_palletized,
  max_temp,
  min_temp,
  package_groups,
  packaging_type,
  packing_count,
  pallet_count,
  pallet_type,
  total_weight,
  trailer_size,
  trailer_type,
  unit_count,
  weight_per_unit,
  weight_unit,
}) => ({
  commodities,
  driverPreference: driver_preference === undefined ? undefined : driverPreferenceDictionary[driver_preference],
  isPalletized: is_palletized,
  maxTemp: max_temp,
  minTemp: min_temp,
  packageGroups: package_groups === undefined ? undefined : package_groups.map(mapToShipmentPackageGroup),
  packagingType: packaging_type === undefined ? undefined : packagingTypeDictionary[packaging_type],
  packingCount: packing_count,
  palletCount: pallet_count,
  palletType: pallet_type === undefined ? undefined : palletTypeDictionary[pallet_type],
  totalWeight: total_weight,
  trailerSize: trailer_size,
  trailerType: trailer_type,
  unitCount: unit_count,
  weightPerUnit: weight_per_unit,
  weightUnit: weight_unit === undefined ? undefined : weightUnitDictionary[weight_unit],
});

export const mapToShipmentOwner: MappingFunction<RawShipmentOwner, ShipmentOwner> = ({
  id,
  first_name,
  last_name,
  email,
}) => ({
  id,
  firstName: first_name,
  lastName: last_name,
  email,
});

export const mapToShipmentBookedQuote: MappingFunction<RawBookedQuote, ShipmentBookedQuote> = ({
  id,
  booking_notes,
  company_name,
  created_at,
  notes,
  rate_per_load,
  status,
  submitter_email,
  total_amount,
}) => ({
  id,
  bookingNotes: booking_notes,
  companyName: company_name,
  createdAt: created_at,
  notes,
  ratePerLoad: rate_per_load,
  status,
  submitterEmail: submitter_email,
  totalAmount: total_amount,
});

export const mapToShipmentQuoteDetails: MappingFunction<RawBookedQuote, ShipmentQuoteDetails> = (input) => ({
  ...mapToShipmentBookedQuote(input),
  ratePerLoad: input.rate_per_load,
  timestamp: new Date(input.created_at).getTime(),
  quotesFromTheSameCompany: 0,
  quoteNumberWithinGroup: 1,
  transitTime: { min: input.min_transit_time, max: input.max_transit_time },
});

export const mapToShipment: MappingFunction<RawShipment, Shipment> = ({
  id,
  accessorials,
  additional_notes,
  booking_date,
  conversation_id,
  files,
  goods_value,
  hazardous_goods_details,
  is_hazardous,
  load_spec,
  mode,
  owner,
  portex_id,
  reference_number,
  status,
  stops,
  trucks,
  estimated_mileage,
  canceled_reason,
  adjusted_total,
  version_number,
  shipper,
  partner,
  lane,
  equipment,
  last_status_update_at,
  partner_contact_id,
  rating,
  rating_notes,
  ...remaining
}) => {
  const mappedStops = stops.map(mapToShipmentStop);
  const origin = mappedStops[0];
  const destination = mappedStops[mappedStops.length - 1];

  let sources: ShipmentSource;

  if (remaining.source_type === "quote") {
    sources = {
      bookedQuote: mapToShipmentBookedQuote(remaining.booked_quote),
      quoteHistory: remaining.quote_history ? remaining.quote_history.map(mapToShipmentBookedQuote) : [],
      quoteRequestId: remaining.quote_request_id,
      sourceType: "quote",
    };
  } else {
    sources = {
      sourceType: "dispatch",
      dispatchRequest: remaining.dispatch_request,
    };
  }

  return {
    id,
    accessorials: (accessorials || []).map(mapToShipmentAccessorial),
    additionalNotes: additional_notes || undefined,
    hazardousGoodsDetails: hazardous_goods_details || undefined,
    isHazardous: is_hazardous ?? undefined,
    bookingDate: booking_date,
    conversationId: conversation_id,
    destination,
    fileIds: files || [],
    goodsValue: goods_value ? goods_value : undefined,
    loadSpec: mapToShipmentLoadSpec(load_spec),
    mode: mapToShipmentMode(mode),
    origin,
    owner: owner ? mapToShipmentOwner(owner) : undefined,
    portexId: portex_id,
    referenceNumber: reference_number || "",
    state: mapToShipmentState(status),
    stops: mappedStops,
    trucks: trucks ? trucks.map(mapToShipmentTruck) : undefined,
    estimated_mileage: estimated_mileage ?? undefined,
    canceledReason: canceled_reason,
    adjusted_total: adjusted_total ?? undefined,
    versionNumber: version_number,
    shipper,
    partner,
    lane,
    equipment,
    lastStatusUpdateAt: last_status_update_at,
    partner_contact_id,
    rating,
    rating_notes,
    ...sources,
  };
};

export const mapToBaseShipment: MappingFunction<RawBaseShipment, BaseShipment> = ({
  id,
  load_spec,
  mode,
  portex_id,
  reference_number,
  status,
  stops,
  trucks,
  partner,
}) => {
  const mappedStops = stops.map(mapToShipmentStop);
  const origin = mappedStops[0];
  const destination = mappedStops[mappedStops.length - 1];

  return {
    id,
    destination,
    loadSpec: mapToShipmentLoadSpec({ ...load_spec, package_groups: [] }),
    mode: mapToShipmentMode(mode),
    origin,
    portexId: portex_id,
    referenceNumber: reference_number || "",
    state: mapToShipmentState(status),
    stops: mappedStops,
    trucks: trucks ? trucks.map(mapToShipmentTruck) : undefined,
    partner: { companyName: partner.company_name },
  };
};

export const mapFromShipmentStop: MappingFunction<ShipmentStop, RawStop> = ({
  id,
  address,
  referenceNumber,
  end,
  isTimeTbd,
  note,
  bookingNotes,
  start,
}) => {
  return {
    id,
    note: transformNullableStringToEmptyString(note),
    booking_notes: bookingNotes,
    address: address ? mapFromShipmentAddress(address) : undefined,
    end,
    start,
    reference_number: transformNullableStringToEmptyString(referenceNumber),
    is_time_tbd: isTimeTbd,
  };
};

export const mapFromShipmentTruck: MappingFunction<ShipmentTruck, RawTruck> = ({
  id,
  referenceNumber,
  trackingLink,
}) => ({
  id,
  reference_number: transformNullableStringToEmptyString(referenceNumber),
  tracking_link: trackingLink,
});

export const mapFromPartialLoadSpec: MappingFunction<Partial<ShipmentLoadSpec>, Partial<RawLoadSpec>> = ({
  commodities,
  driverPreference,
  isPalletized,
  maxTemp,
  minTemp,
  packageGroups,
  packagingType,
  packingCount,
  palletCount,
  palletType,
  totalWeight,
  trailerSize,
  trailerType,
  unitCount,
  weightPerUnit,
  weightUnit,
}) => ({
  weight_unit: weightUnit === undefined || weightUnit === null ? weightUnit : reverseWeightUnitDictionary[weightUnit],
  packing_count: packingCount,
  weight_per_unit: weightPerUnit,
  unit_count: unitCount,
  total_weight: totalWeight,
  pallet_type: palletType === undefined || palletType === null ? palletType : reversePalletTypeDictionary[palletType],
  package_groups: packageGroups === undefined ? undefined : packageGroups.map(mapFromShipmentPackageGroup),
  packaging_type:
    packagingType === undefined || packagingType === null
      ? packagingType
      : reversePackagingTypeDictionary[packagingType],
  is_palletized: isPalletized,
  driver_preference:
    driverPreference === undefined || driverPreference === null
      ? driverPreference
      : reverseDriverPreferenceDictionary[driverPreference],
  pallet_count: palletCount,
  commodities,
  max_temp: maxTemp,
  min_temp: minTemp,
  trailer_size: trailerSize,
  trailer_type: trailerType,
});

const mapFromPartialBookedQuote: MappingFunction<Partial<ShipmentBookedQuote>, Partial<RawBookedQuote>> = ({
  ratePerLoad,
  totalAmount,
}) => ({
  rate_per_load: ratePerLoad,
  total_amount: totalAmount,
});

export const mapFromPartialShipment: MappingFunction<
  Partial<Omit<Shipment, "loadSpec" | "bookedQuote">> & {
    loadSpec?: Partial<ShipmentLoadSpec>;
    bookedQuote?: Partial<ShipmentBookedQuote>;
  },
  Partial<Omit<RawShipment, "load_spec" | "booked_quote">> & {
    load_spec?: Partial<RawLoadSpec>;
    booked_quote?: Partial<RawBookedQuote>;
  }
> = ({
  accessorials,
  additionalNotes,
  bookedQuote,
  canceledReason,
  fileIds,
  goodsValue,
  hazardousGoodsDetails,
  isHazardous,
  loadSpec,
  referenceNumber,
  state,
  stops,
  trucks,
  adjusted_total,
  versionNumber,
  rating,
  rating_notes,
}) => ({
  accessorials: accessorials?.map(mapFromShipmentAccessorial),
  additional_notes: transformNullableStringToEmptyString(additionalNotes),
  booked_quote: bookedQuote ? mapFromPartialBookedQuote(bookedQuote) : undefined,
  canceled_reason: canceledReason,
  files: fileIds,
  goods_value: goodsValue,
  hazardous_goods_details: transformNullableStringToEmptyString(hazardousGoodsDetails),
  is_hazardous: isHazardous,
  load_spec: loadSpec ? mapFromPartialLoadSpec(loadSpec) : undefined,
  reference_number: transformNullableStringToEmptyString(referenceNumber),
  status: state ? reverseShipmentStateDictionary[state] : undefined,
  stops: stops ? stops.map(mapFromShipmentStop) : undefined,
  trucks: trucks ? trucks.map(mapFromShipmentTruck) : undefined,
  adjusted_total: adjusted_total ?? undefined,
  version_number: versionNumber,
  rating,
  rating_notes,
});

export const mapToUpdateShipmentAddressPayload: MappingFunction<
  Partial<ShipmentAddress>,
  Partial<UpdateShipmentAddressPayload>
> = ({
  address1,
  address2,
  city,
  contact,
  countryCode,
  countryName,
  hoursEnd,
  hoursStart,
  name,
  provinceCode,
  provinceName,
  zip,
}) => ({
  address_1: address1,
  address_2: address2,
  city,
  address_contacts: contact ? [mapFromShipmentContact(contact)] : undefined,
  country_code: countryCode,
  country_name: countryName,
  hours_end: hoursEnd ? hoursEnd : undefined,
  hours_start: hoursStart ? hoursStart : undefined,
  name,
  province_code: provinceCode,
  province_name: provinceName,
  zip,
});

export const transformListShipmentsResponse: MappingFunction<
  ListShipmentsApi["response"],
  ListShipmentsApi["hookReturnValue"]
> = ({ data: { shipments }, cursor, total }) => ({
  total,
  cursor,
  data: shipments.map(mapToBaseShipment),
});

export const transformShipmentDetailsResponse: MappingFunction<ShipmentDetailsApi["response"], Shipment> = ({ data }) =>
  mapToShipment(data.shipment);

export const transformAccessorialListResponse: MappingFunction<
  ListAccessorialOptionsApi["response"],
  ListAccessorialOptionsApi["hookReturnValue"]
> = ({ data: { accessorials } }) => accessorials.map(mapToShipmentAccessorial);

export const transformShipmentQuotesResponse: MappingFunction<
  ListShipmentQuotesApi["response"],
  ListShipmentQuotesApi["hookReturnValue"]
> = ({ data: { quotes } }) => {
  const mappedQuotes = quotes
    .map(mapToShipmentQuoteDetails)
    .filter((quote) => ["SUBMITTED", "BOOKED", "CANCELED"].includes(quote.status))
    .sort((a, b) => a.ratePerLoad - b.ratePerLoad);

  const numQuotesPerSubmitter = mappedQuotes.reduce<Record<string, number>>((acc, quote) => {
    acc[quote.submitterEmail] = (acc[quote.submitterEmail] || 0) + 1;
    quote.quoteNumberWithinGroup = acc[quote.submitterEmail];

    return acc;
  }, {});

  mappedQuotes.forEach((quote) => (quote.quotesFromTheSameCompany = numQuotesPerSubmitter[quote.submitterEmail] || 1));

  return mappedQuotes;
};

export const mapToBrokerShipmentLoadSpec: MappingFunction<RawBrokerShipment["load_spec"], BrokerShipment["load_spec"]> =
  ({ trailer_size, trailer_type, commodities, is_palletized, packaging_type, driver_preference }) => ({
    trailer_size: trailer_size || undefined,
    trailer_type: trailer_type || undefined,
    commodities,
    is_palletized,
    packaging_type: packaging_type ? packagingTypeDictionary[packaging_type] : undefined,
    driver_preference: driver_preference || undefined,
  });

export const mapToBrokerShipmentStop: MappingFunction<RawBrokerShipment["stops"][0], BrokerShipment["stops"][0]> = ({
  id,
  position,
  name,
  address_1,
  address_2,
  city,
  province_code,
  province_name,
  zip,
  country_code,
  country_name,
  date_start,
  date_end,
  is_time_tbd,
  hours_of_operation_start,
  hours_of_operation_end,
  reference_number,
  operations_contact,
  iana_timezone,
  note,
  booking_notes,
}) => {
  return {
    id,
    position,
    name: name || undefined,
    address_1: address_1 || undefined,
    address_2: address_2 || undefined,
    city: city || undefined,
    province_code: province_code || undefined,
    province_name: province_name || undefined,
    zip: zip || undefined,
    country_code: country_code || undefined,
    country_name: country_name || undefined,
    date_start: date_start || undefined,
    date_end: date_end || undefined,
    is_time_tbd: is_time_tbd ?? undefined,
    iana_timezone: iana_timezone ?? undefined,
    hours_of_operation_start: hours_of_operation_start || undefined,
    hours_of_operation_end: hours_of_operation_end || undefined,
    reference_number,
    operations_contact: {
      first_name: operations_contact?.first_name || undefined,
      last_name: operations_contact?.last_name || undefined,
      phone_number: operations_contact?.phone_number || undefined,
      email: operations_contact?.email || undefined,
    },
    note: note,
    booking_notes: booking_notes,
  };
};

export const mapToBrokerShipment: MappingFunction<RawBrokerShipment, BrokerShipment> = ({
  load_spec,
  truck_quantity,
  trucks,
  stops,
  miles,
  shipper_name,
  mode,
  portex_id,
  reference_number,
  lane,
  equipment,
}) => {
  return {
    load_spec: mapToBrokerShipmentLoadSpec(load_spec),
    truck_quantity,
    trucks,
    stops: stops.map(mapToBrokerShipmentStop),
    miles: miles || null,
    shipper_name,
    mode,
    portex_id,
    reference_number,
    lane,
    equipment,
  };
};

export const transformBrokerShipmentDetailsResponse: MappingFunction<
  ApiResponse<{ shipment: RawBrokerShipment }>,
  ApiResponse<{ shipment: BrokerShipment }>
> = ({ data }) => ({ data: { shipment: mapToBrokerShipment(data.shipment) } });

export const mapToPublicShipment: MappingFunction<RawPublicShipment, PublicShipment> = ({
  id,
  booking_date,
  equipment,
  mode,
  lane,
  partner,
  portex_id,
  reference_number,
  shipper,
  source_type,
  stops,
  trucks,
  last_status_update_at,
}) => ({
  _type: "PublicShipment",
  id,
  bookingDate: booking_date,
  equipment,
  lane,
  mode,
  partner,
  portexId: portex_id,
  referenceNumber: reference_number || "",
  shipper,
  sourceType: source_type,
  stops: stops.map(mapToShipmentStop),
  trucks: trucks ? trucks.map(mapToShipmentTruck) : undefined,
  lastStatusUpdateAt: last_status_update_at,
});

export const transformGetPublicShipmentResponse: MappingFunction<
  ApiResponse<{ shipment: RawPublicShipment }>,
  ApiResponse<{ shipment: PublicShipment }>
> = ({ data }) => ({ data: { shipment: mapToPublicShipment(data.shipment) } });
