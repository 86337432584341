import { FC, VFC } from "react";

import { portexColor, Status, styled, Tab, Tabs } from "@portex-pro/ui-components";
import useLDFlag from "hooks/useLDFlag";
import { ResourceKey } from "i18next";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Shipment, ShipmentTabId, ShipmentSource } from "../types/domain";

const CustomTab = styled(Tab)({
  "&.MuiTab-labelIcon .MuiTab-wrapper > *:first-child": {
    margin: "0 0 0 4px",
  },
});

const StyledTabs = styled(Tabs)({
  borderBottom: `1px solid ${portexColor.grey300}`,
  backgroundColor: "white",
  flexShrink: 0,
});

export interface ShipmentTab {
  id: ShipmentTabId;
  badge?: string;
  label: ResourceKey;
  path: string;
  sourceType?: ShipmentSource["sourceType"];
  labelComponent?: FC;
}

interface ShipmentDetailTabsProps {
  activeTabId: ShipmentTabId;
  shipment: Shipment;
  tabs: ShipmentTab[];
}

const ShipmentDetailsTabsContainer: VFC<ShipmentDetailTabsProps> = ({ activeTabId, shipment, tabs }) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const rolloutBrokerScorecards = useLDFlag("rolloutBrokerScorecards");

  return (
    <StyledTabs
      value={activeTabId}
      onChange={(_event, value) => {
        push(`/shipper/shipments/${shipment.id}/${value}`);
      }}
    >
      {tabs
        .filter((tab) => !tab.sourceType || tab.sourceType === shipment.sourceType)
        .filter((tab) => tab.id !== "scorecard" || (tab.id === "scorecard" && !!rolloutBrokerScorecards))
        .map(({ id, label, badge, labelComponent: LabelComponent = ({ children }) => <>{children}</> }) => (
          <CustomTab
            key={id}
            value={id}
            label={
              <LabelComponent>
                <span>{t(label)}</span>
                {badge && <Status className="Por-tabBadge">{badge}</Status>}
              </LabelComponent>
            }
          />
        ))}
    </StyledTabs>
  );
};

export default ShipmentDetailsTabsContainer;
