import { TrailerType, TrailerTypeEnum } from "types/TrailerType";

const TEMPERATURE_CONTROLLED_TRAILERS: TrailerType[] = [
  TrailerTypeEnum.FOOD_GRADE_TANK,
  TrailerTypeEnum.REEFER,
  TrailerTypeEnum.DRAYAGE_REEFER,
];

const isTemperatureControlled = (trailerType: TrailerType | undefined): boolean =>
  trailerType !== undefined && TEMPERATURE_CONTROLLED_TRAILERS.includes(trailerType);

export default isTemperatureControlled;
