import { SVGProps, VFC } from "react";

const ShareArrowSvg: VFC<SVGProps<SVGSVGElement>> = ({ width = 20, height = 20, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.5 9.6875L11.25 3.75V7.08336C5.41664 7.91668 3.33332 12.0834 2.5 16.25C4.58332 13.3334 7.08332 11.9999 11.25 11.9999V15.4167L17.5 9.6875Z" />
    </svg>
  );
};

export default ShareArrowSvg;
