import { configureSlices } from "utils/store/configureSlices";

import generateBOLSlice from "./generateBOLSlice";

const slicesMap = {
  generateBOLSlice,
};

export const { useSlices: useGenerateBOLSlices, useSliceSelector: useGenerateBOLSliceSelector } =
  configureSlices(slicesMap);
