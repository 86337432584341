import { VFC } from "react";

import { makeStyles, TextInput } from "@portex-pro/ui-components";
import FormStringConcat from "@portex-pro/ui-components/Forms/FormStringConcat";
import { freightClassDictionary } from "app/pages/shipments/components/EditableShipmentPackageGroupElements";
import { ShipmentFreightClass, ShipmentPackagingType } from "app/pages/shipments/types/domain";
import PositiveNumberInput from "components/PositiveNumberInput";
import Text from "components/Text";
import { PackingGroup as PackingGroupType } from "features/generate-bol/store/generateBOLSlice";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import { useTranslation } from "react-i18next";

// We are omitting the different types of pallets here for simplicity
const packagingTypeOptions = Object.values(ShipmentPackagingType).filter(
  (packagingType) => !packagingType.includes("PALLETS_")
);
const freightClassOptions = Object.values(ShipmentFreightClass);

const useNumberInputStyles = makeStyles({
  middle: {
    "& > fieldset": {
      borderLeft: "none",
      borderRight: "none",
    },
    // This styling hides the number input spinners
    "& > input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& > input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  base: {
    "& > input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& > input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
});

interface PackingGroupProps {
  packingGroup: PackingGroupType;
  gridPosition: number;
  packingGroupIndex: number;

  onChangeCargoDetail: (changes: Partial<PackingGroupType>, index: number) => void;
}

const PackingGroupView: VFC<PackingGroupProps> = (props) => {
  const { packingGroup, gridPosition, packingGroupIndex, onChangeCargoDetail } = props;
  const numberInputClasses = useNumberInputStyles();
  const { t } = useTranslation(["shipper"]);

  return (
    <>
      <div className={`px-2 col-start-1 row-start-${gridPosition} flex flex-row items-center justify-center`}>
        <PositiveNumberInput
          disableError
          value={packingGroup.count}
          style={{ width: "80px" }}
          onChange={(count) => onChangeCargoDetail({ count }, packingGroupIndex)}
        />
        <span className="px-2">x</span>
        <FormSelectView
          value={packingGroup.item}
          items={packagingTypeOptions}
          getItemCopy={(option) => t(`shipper:packingTypeMap.${option}`)}
          formControlProps={{ fullWidth: true }}
          onChange={(item) => onChangeCargoDetail({ item }, packingGroupIndex)}
        />
      </div>
      <div className={`px-2 col-start-2 row-start-${gridPosition} flex flex-row items-center justify-center`}>
        <PositiveNumberInput
          value={packingGroup.weight}
          disableError
          endIcon={
            <Text size="small" typographyProps={{ color: "textSecondary" }}>
              {t("shipper:loadAttributesStep.pounds")}
            </Text>
          }
          fullWidth
          onChange={(weight) => onChangeCargoDetail({ weight }, packingGroupIndex)}
        />
      </div>
      <div className={`px-2 col-start-3 row-start-${gridPosition} flex flex-row items-center justify-center`}>
        <PositiveNumberInput
          value={packingGroup.xDimension}
          disableError
          allowFloat
          fullWidth
          InputProps={{
            style: { borderTopRightRadius: 0, borderBottomRightRadius: 0, minWidth: "40px" },
            className: numberInputClasses.base,
          }}
          inputProps={{ style: { textAlign: "center" } }}
          onChange={(xDimension) => onChangeCargoDetail({ xDimension }, packingGroupIndex)}
        />
        <PositiveNumberInput
          value={packingGroup.yDimension}
          disableError
          allowFloat
          fullWidth
          InputProps={{ style: { borderRadius: 0, minWidth: "40px" }, className: numberInputClasses.middle }}
          inputProps={{ style: { textAlign: "center" } }}
          onChange={(yDimension) => onChangeCargoDetail({ yDimension }, packingGroupIndex)}
        />
        <PositiveNumberInput
          value={packingGroup.zDimension}
          disableError
          allowFloat
          fullWidth
          InputProps={{
            style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, minWidth: "40px" },
            className: numberInputClasses.base,
          }}
          inputProps={{ style: { textAlign: "center" } }}
          onChange={(zDimension) => onChangeCargoDetail({ zDimension }, packingGroupIndex)}
        />
      </div>
      <div className={`px-2 col-start-4 row-start-${gridPosition} flex flex-row items-center justify-center border-r`}>
        <FormStringConcat
          value={packingGroup.commodities ?? ""}
          onChange={(commodities) => onChangeCargoDetail({ commodities }, packingGroupIndex)}
          placeholder="Enter your commodities"
          TextInputProps={{ fullWidth: true, style: { margin: 0 } }}
        />
      </div>
      <div className={`px-2 col-start-5 row-start-${gridPosition} flex flex-row items-center justify-center`}>
        <TextInput
          value={packingGroup.nmfc}
          fullWidth
          onChange={(event) => onChangeCargoDetail({ nmfc: event.target.value }, packingGroupIndex)}
        />
      </div>
      <div className={`px-2 col-start-6 row-start-${gridPosition} flex flex-row items-center justify-center`}>
        <FormSelectView
          value={packingGroup.class}
          items={freightClassOptions}
          getItemCopy={(option) => freightClassDictionary[option]}
          formControlProps={{ fullWidth: true }}
          onChange={(c) => onChangeCargoDetail({ class: c }, packingGroupIndex)}
        />
      </div>
    </>
  );
};

export default PackingGroupView;
