import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import { TypedDocumentNode, gql, useLazyQuery } from "@apollo/client";
import {
  Box,
  Checkbox,
  Dropdown,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Statistic,
  Typography,
} from "@portex-pro/ui-components";

import {
  EquipmentType,
  Mode,
  Order,
  Query,
  QueryGetFreightSpendByLaneArgs,
  QueryGetPartnerVolumeStatsArgs,
  QueryGetQuoteCostsArgs,
  QueryGetQuoteCountsArgs,
  QueryGetQuoteFrequencyArgs,
  QuoteFrequencyTimeScale,
  QuoteStatus,
} from "../../../../../api/types/generated-types";
import { useOnApolloError } from "../../../../../hooks/useOnApolloError";
import { toLuxon } from "../../../../../utils/toLuxon";
import BasicAreaChart from "./chart/BasicAreaChart";
import BasicBarChart from "./chart/BasicBarChart";
import { Data } from "./chart/types";
import VerticalBarChart from "./chart/VerticalBarChart";
import DateRangePicker, { DateRange } from "./DateRangePicker";
import { InsightWidget, InsightWidgetGraph, InsightWidgetHeader } from "./InsightWidget";
import NoResults from "./NoResults";

const useStyles = makeStyles((theme) => ({
  filter: {
    "& label": {
      fontWeight: "bold",
      textTransform: "uppercase",
      marginRight: "0.75rem",
      color: theme.palette.text.primary,
    },
  },
  statsList: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2.5),
    "& > div:not(.MuiBox-root)": {
      flexGrow: 1,
    },
  },
}));

export const GET_QUOTE_COUNTS: TypedDocumentNode<Pick<Query, "getQuoteCounts">, QueryGetQuoteCountsArgs> = gql`
  query ($input: InsightInput!) {
    getQuoteCounts(input: $input) {
      requestedCount
      returnedCount
      bookedCount
    }
  }
`;

export const GET_QUOTE_COSTS: TypedDocumentNode<Pick<Query, "getQuoteCosts">, QueryGetQuoteCostsArgs> = gql`
  query ($input: InsightInput!) {
    getQuoteCosts(input: $input) {
      returnedCost
      bookedCost
    }
  }
`;

const GET_QUOTE_FREQUENCY: TypedDocumentNode<Pick<Query, "getQuoteFrequency">, QueryGetQuoteFrequencyArgs> = gql`
  query ($input: InsightInput!, $timeScale: QuoteFrequencyTimeScale!) {
    getQuoteFrequency(input: $input, timeScale: $timeScale) {
      result {
        date
        count
      }
    }
  }
`;

const GET_PARTNER_VOLUME_STATS: TypedDocumentNode<
  Pick<Query, "getPartnerVolumeStats">,
  QueryGetPartnerVolumeStatsArgs
> = gql`
  query ($input: PartnerVolumeStatsInput!) {
    getPartnerVolumeStats(input: $input) {
      stats {
        partnerName
        value
      }
    }
  }
`;

export const GET_FREIGHT_SPEND_BY_LANE: TypedDocumentNode<
  Pick<Query, "getFreightSpendByLane">,
  QueryGetFreightSpendByLaneArgs
> = gql`
  query ($input: FreightSpendByLaneInput!) {
    getFreightSpendByLane(input: $input) {
      start
      end
      items {
        origin
        destination
        number_of_loads_moved
        total_amount
        mode
      }
    }
  }
`;

type Props = {
  //
};

const InsightAnalyticsOverview = ({}: Props): ReactElement => {
  const { onApolloError } = useOnApolloError({ componentName: "InsightAnalyticsOverview" });
  const classes = useStyles();

  const [range, setRange] = useState<DateRange>({
    start: null,
    end: null,
  });
  const [equipmentType, setEquipmentType] = useState<EquipmentType>(EquipmentType.All);

  const [getQuoteCounts, { data: quoteCountsData, called }] = useLazyQuery(GET_QUOTE_COUNTS, {
    variables: { input: { modes: [Mode.Ftl], start: range.start?.toJSDate(), end: range.end?.toJSDate() } },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getQuoteCounts"),
  });
  const [getQuoteCosts, { data: quoteCostsData }] = useLazyQuery(GET_QUOTE_COSTS, {
    variables: { input: { modes: [Mode.Ftl], start: range.start?.toJSDate(), end: range.end?.toJSDate() } },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getQuoteCosts"),
  });
  const [
    getQuoteFrequency,
    { called: getQuoteFrequencyCalled, loading: getQuoteFrequencyLoading, data: quoteFrequencyData },
  ] = useLazyQuery(GET_QUOTE_FREQUENCY, {
    variables: {
      input: { modes: [Mode.Ftl], start: range.start?.toJSDate(), end: range.end?.toJSDate() },
      timeScale: QuoteFrequencyTimeScale.Monthly,
    },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getQuoteFrequency"),
  });
  const [
    getPartnerVolumeStats,
    { called: getPartnerVolumeStatsCalled, loading: getPartnerVolumeStatsLoading, data: partnerVolumeStatsData },
  ] = useLazyQuery(GET_PARTNER_VOLUME_STATS, {
    variables: {
      input: {
        insightInput: { modes: [Mode.Ftl], start: range.start?.toJSDate(), end: range.end?.toJSDate() },
        quoteStatus: QuoteStatus.Booked,
        orderBy: Order.Desc,
        count: 5,
      },
    },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getPartnerVolumeStats"),
  });
  const [
    getFreightSpendByLane,
    { called: getFreightSpendByLaneCalled, loading: getFreightSpendByLaneLoading, data: freightSpendByLaneData },
  ] = useLazyQuery(GET_FREIGHT_SPEND_BY_LANE, {
    variables: {
      input: {
        mode: Mode.Ftl,
        equipment_type: equipmentType,
        start: range.start?.toJSDate(),
        end: range.end?.toJSDate(),
        orderBy: Order.Desc,
        count: 5,
      },
    },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getFreightSpendByLane"),
  });

  const fetchData = useCallback(() => {
    getQuoteCounts({
      variables: { input: { modes: [Mode.Ftl], start: range.start?.toJSDate(), end: range.end?.toJSDate() } },
    });
    getQuoteCosts({
      variables: { input: { modes: [Mode.Ftl], start: range.start?.toJSDate(), end: range.end?.toJSDate() } },
    });
    getQuoteFrequency({
      variables: {
        input: { modes: [Mode.Ftl], start: range.start?.toJSDate(), end: range.end?.toJSDate() },
        timeScale: QuoteFrequencyTimeScale.Monthly,
      },
    });
    getPartnerVolumeStats({
      variables: {
        input: {
          insightInput: { modes: [Mode.Ftl], start: range.start?.toJSDate(), end: range.end?.toJSDate() },
          quoteStatus: QuoteStatus.Booked,
          orderBy: Order.Desc,
          count: 5,
        },
      },
    });
    getFreightSpendByLane({
      variables: {
        input: {
          mode: Mode.Ftl,
          equipment_type: equipmentType,
          start: range.start?.toJSDate(),
          end: range.end?.toJSDate(),
          orderBy: Order.Desc,
          count: 5,
        },
      },
    });
  }, [
    equipmentType,
    getFreightSpendByLane,
    getPartnerVolumeStats,
    getQuoteCosts,
    getQuoteCounts,
    getQuoteFrequency,
    range.end,
    range.start,
  ]);

  useEffect(() => {
    if (called) return;

    fetchData();
  }, [called, fetchData]);

  useEffect(() => {
    if (!range.start || !range.end) return;

    fetchData();
  }, [fetchData, range.end, range.start]);
  useEffect(() => {
    if (!range.start && !range.end) {
      getFreightSpendByLane({
        variables: {
          input: {
            mode: Mode.Ftl,
            equipment_type: equipmentType,
            orderBy: Order.Desc,
            count: 5,
          },
        },
      });
    }
  }, [equipmentType, getFreightSpendByLane, range.end, range.start]);

  const quoteCounts = quoteCountsData?.getQuoteCounts;
  const quoteCosts = quoteCostsData?.getQuoteCosts;
  const quoteFrequency = quoteFrequencyData?.getQuoteFrequency?.result;
  const quotesByFrequency: Data[] = useMemo(() => {
    return quoteFrequency
      ? quoteFrequency?.map((frequency) => ({
          [Mode.Ftl]: frequency.count,
          [Mode.Fcl]: 0,
          [Mode.Air]: 0,
          name: toLuxon(frequency.date).toFormat("LLL, yyyy"),
        }))
      : [];
  }, [quoteFrequency]);

  const partnerVolumeStats = partnerVolumeStatsData?.getPartnerVolumeStats?.stats;
  const partnersByVolumeStats: Data[] = useMemo(() => {
    return partnerVolumeStats
      ? partnerVolumeStats.map((volumeStat) => ({
          [Mode.Ftl]: volumeStat.value,
          [Mode.Fcl]: 0,
          [Mode.Air]: 0,
          name: volumeStat.partnerName,
        }))
      : [];
  }, [partnerVolumeStats]);

  const partnerVolumeStatsItems = freightSpendByLaneData?.getFreightSpendByLane?.items;
  const topLanes: Data[] = useMemo(() => {
    return partnerVolumeStatsItems
      ? partnerVolumeStatsItems.map((volumeStat) => ({
          [Mode.Ftl]: volumeStat.total_amount,
          [Mode.Fcl]: 0,
          [Mode.Air]: 0,
          name: `${volumeStat.origin} to ${volumeStat.destination}`,
        }))
      : [];
  }, [partnerVolumeStatsItems]);

  const totalOpenQuotesValue = useMemo<number>(() => {
    const requestedCount = quoteCounts?.requestedCount ?? 0;
    const returnedCount = quoteCounts?.returnedCount ?? 0;
    return requestedCount + returnedCount;
  }, [quoteCounts?.requestedCount, quoteCounts?.returnedCount]);

  const getEquipmentTypeLabel = (index: EquipmentType): string => {
    const labels: Record<EquipmentType, string> = {
      ALL: "All",
      BULK: "Bulk",
      DRY: "Dry Van",
      FLATBED: "Flatbed",
      RGN: "Removable Gooseneck (RGN)",
      REEFER: "Reefer",
      FOOD_GRADE_TANK: "Food Grade Tank",
      STEP_DECK: "Step Deck",
      BOX_TRUCK: "Box Truck",
      SPRINTER_VAN: "Sprinter Van",
      CONESTOGA: "Conestoga",
      HOTSHOT: "Hotshot",
      DRAYAGE: "Drayage",
      DRAYAGE_REEFER: "Drayage Reefer",
      CARGO_VAN: "Cargo Van",
      DUMP: "Dump",
      PARTIAL_LOAD: "Partial Load",
      NA_PARTNERS_CHOICE: "N/A - Partner's Choice",
      OTHER: "Other",
    };

    return labels[index];
  };

  return (
    <>
      <Box px={2.5} py={2} display="flex" alignItems="center" className={classes.filter}>
        <FormLabel>Filter</FormLabel>
        <DateRangePicker id="insight-report-range-picker" value={range} onChange={setRange} />
      </Box>
      <Box display="flex" px={2.5} pb={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Paper className="Por-border-grey200" style={{ height: "100%" }}>
              <Box className={classes.statsList}>
                <Statistic label="Total Open Quotes" value={totalOpenQuotesValue} />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper className="Por-border-grey200" style={{ height: "100%" }}>
              <Box className={classes.statsList}>
                <Statistic label="Quotes Pending Booking" value={quoteCounts ? quoteCounts.returnedCount : 0} />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper className="Por-border-grey200" style={{ height: "100%" }}>
              <Box className={classes.statsList}>
                <Statistic label="Quotes Booked" value={quoteCounts?.bookedCount ?? 0} />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper className="Por-border-grey200" style={{ height: "100%" }}>
              <Box className={classes.statsList}>
                <Statistic
                  label="Quotes Booked"
                  value={quoteCosts ? `$${quoteCosts.bookedCost.toLocaleString()}` : "$0"}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <InsightWidget>
              <InsightWidgetHeader>
                <Typography variant="body2">
                  Top Partners by <strong>Quotes Booked</strong>
                </Typography>
                <FormGroup row>
                  <FormControlLabel control={<Checkbox disabled={true} defaultChecked name="FTL" />} label="FTL" />
                  <FormControlLabel control={<Checkbox disabled={true} name="FCL" />} label="FCL" />
                  <FormControlLabel control={<Checkbox disabled={true} name="Air" />} label="Air" />
                </FormGroup>
              </InsightWidgetHeader>
              {partnersByVolumeStats.length > 0 ? (
                <InsightWidgetGraph py={3}>
                  <BasicBarChart
                    id="quote-booked-chart"
                    data={partnersByVolumeStats.sort(({ name: a }, { name: b }) => a.localeCompare(b))}
                    dataKey="FTL"
                    tooltipUnit="Quotes booked"
                  />
                </InsightWidgetGraph>
              ) : null}
              {getPartnerVolumeStatsCalled && !getPartnerVolumeStatsLoading && partnersByVolumeStats.length === 0 ? (
                <NoResults />
              ) : null}
            </InsightWidget>
          </Grid>
          <Grid item xs={12} lg={6}>
            <InsightWidget>
              <InsightWidgetHeader>
                <Typography variant="body2">
                  Historical Frequency of <strong>Quote Bookings</strong>
                </Typography>
                <FormGroup row>
                  <FormControlLabel control={<Checkbox disabled={true} defaultChecked name="FTL" />} label="FTL" />
                  <FormControlLabel control={<Checkbox disabled={true} name="FCL" />} label="FCL" />
                  <FormControlLabel control={<Checkbox disabled={true} name="Air" />} label="Air" />
                </FormGroup>
              </InsightWidgetHeader>
              {quotesByFrequency.length > 0 ? (
                <InsightWidgetGraph py={3} px={2}>
                  <BasicAreaChart id="quote-bookings-area-chart" data={quotesByFrequency} tooltipUnit="Quotes booked" />
                </InsightWidgetGraph>
              ) : null}
              {getQuoteFrequencyCalled && !getQuoteFrequencyLoading && quotesByFrequency.length === 0 ? (
                <NoResults />
              ) : null}
            </InsightWidget>
          </Grid>

          <Grid item md={12}>
            <InsightWidget>
              <InsightWidgetHeader>
                <Typography variant="body2">
                  <strong>Freight Spend on Top Lanes</strong> in USD
                </Typography>
                <Box display="flex" alignItems="center">
                  <FormGroup row>
                    <FormControlLabel control={<Checkbox disabled={true} defaultChecked name="FTL" />} label="FTL" />
                    <FormControlLabel control={<Checkbox disabled={true} name="FCL" />} label="FCL" />
                    <FormControlLabel control={<Checkbox disabled={true} name="Air" />} label="Air" />
                  </FormGroup>
                  <Box width="1rem" />
                  <Dropdown placeholder={getEquipmentTypeLabel(equipmentType)}>
                    {({ onClose }) => (
                      <>
                        {Object.values(EquipmentType).map((value) => {
                          return (
                            <MenuItem
                              key={value}
                              className={equipmentType === value ? "Por-selected" : ""}
                              onClick={() => {
                                setEquipmentType(value);
                                onClose();
                              }}
                            >
                              {getEquipmentTypeLabel(value)}
                            </MenuItem>
                          );
                        })}
                      </>
                    )}
                  </Dropdown>
                </Box>
              </InsightWidgetHeader>
              {topLanes.length > 0 ? (
                <InsightWidgetGraph height="500px" px={2}>
                  <VerticalBarChart
                    id="top-lanes-vertical-chart"
                    data={topLanes}
                    tickFormatter={(value) =>
                      new Intl.NumberFormat("en", { notation: "compact", compactDisplay: "short" }).format(value)
                    }
                    tooltipUnit="Freight spend (USD)"
                    tooltipFormatter={(value) => {
                      if (typeof value === "number") {
                        return Intl.NumberFormat("en", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                        }).format(value);
                      }
                      return value;
                    }}
                  />
                </InsightWidgetGraph>
              ) : null}
              {getFreightSpendByLaneCalled && !getFreightSpendByLaneLoading && topLanes.length === 0 ? (
                <NoResults />
              ) : null}
            </InsightWidget>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default InsightAnalyticsOverview;
