import { baseRestApi } from "api/rest/baseRestApi";

import { BillOfLadingFormData } from "./types";

const API_ROOT = "/shipper/bill-of-lading/generate";
module.hot?.accept();

type BodyParams = {
  formData: BillOfLadingFormData;
  shipmentId?: string;
  fileName?: string;
  fileDownloadUUID?: string;
};

type QueryArgs = { body: BodyParams };

const createBillOfLadingApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",

  endpoints: (builder) => ({
    createBillOfLading: builder.mutation<void, QueryArgs>({
      query: (params) => ({
        url: `${API_ROOT}`,
        method: "POST",
        body: params.body,
      }),
    }),
  }),
});

export default createBillOfLadingApi;

export const { useCreateBillOfLadingMutation } = createBillOfLadingApi;
