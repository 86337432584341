import { VFC, useState } from "react";

import { Alert, TextInput } from "@portex-pro/ui-components";
import DangerIcon from "assets/information-red.svg";
import PortexDialog from "components/PortexDialog";
import FormRadioGroupView from "pages/shipper/pages/request-quote/pages/ltl/components/FormRadioGroupView";
import { useTranslation } from "react-i18next";

type CancellationType = "cancel-only" | "cancel-recreate" | "cancel-rebook";

interface CancelShipmentDialogViewProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (cancellationType: CancellationType, reason: string) => void;
  showRecreatePrompt?: boolean;
  loading?: boolean;
}

const CancelShipmentDialogView: VFC<CancelShipmentDialogViewProps> = ({
  isOpen,
  onClose,
  onSubmit,
  showRecreatePrompt,
  loading,
}) => {
  const { t } = useTranslation(["common", "shipments"]);
  const [reason, setReason] = useState("");
  const [cancellationType, setCancellationType] = useState<CancellationType>("cancel-only");

  const handleClose = () => {
    setCancellationType("cancel-only");
    setReason("");
    onClose();
  };

  return (
    <PortexDialog
      title={t("shipments:cancelShipment_dialogTitle")}
      maxWidth="sm"
      fullWidth={false}
      open={isOpen}
      onClose={handleClose}
      loading={loading}
      onClickConfirm={() => onSubmit(cancellationType, reason)}
      cancelButtonCopy={t("common:goBack")}
      confirmButtonCopy={t("shipments:cancelShipment_dialogButon")}
      confirmButtonProps={{ className: "Por-bg-red" }}
    >
      <div className="p-5 flex flex-col gap-2">
        <div className="my-2">
          <span className="text-[16px]">{t("shipments:cancelShipment_dialogBody")}</span>
        </div>
        {showRecreatePrompt && (
          <FormRadioGroupView
            value={cancellationType}
            items={["cancel-only", "cancel-rebook", "cancel-recreate"]}
            getItemCopy={(value) => {
              const copyMap = {
                "cancel-only": "shipments:cancelShipment_radio_cancel",
                "cancel-rebook": "shipments:cancelShipment_radio_cancelAndRebook",
                "cancel-recreate": "shipments:cancelShipment_radio_cancelAndRecreate",
              } as const;
              return t(copyMap[value]);
            }}
            onClickRadio={(value) => setCancellationType(value as CancellationType)}
          />
        )}
        <TextInput
          multiline
          rows={3}
          fullWidth
          margin="normal"
          label={t("shipments:cancelShipment_inputLabel_reason")}
          placeholder={t("shipments:cancelShipment_input_placeholder")}
          value={reason}
          onChange={(event) => setReason(event.currentTarget.value)}
        />
        <Alert
          variant="outlined"
          severity="error"
          icon={<img src={DangerIcon} style={{ width: "24px", height: "24px" }} />}
        >
          {t("shipments:cancelShipment_alert")}
        </Alert>
      </div>
    </PortexDialog>
  );
};

export default CancelShipmentDialogView;
