import { ComponentProps, useState } from "react";

import { AppBar } from "@portex-pro/ui-components";
import { useModifyShipperDispatchMutation } from "api/rest/dispatches";
import formatEquipment from "app/pages/shipments/utils/formatEquipment";
import withAsync from "components/withAsync";
import first from "lodash/first";
import last from "lodash/last";
import { DateTime } from "luxon";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import { useBoolean } from "usehooks-ts";

import { useDispatchShipmentAndAwards } from "../../hooks/dispatches/useDispatchShipmentAndAwards";
import CancelDispatchDialogView from "../../views/dispatches/CancelDispatchDialogView";
import DispatchHeaderView from "../../views/dispatches/DispatchHeaderView";
import ResendDispatchDialogView from "../../views/dispatches/ResendDispatchDialogView";
import RequestsAppBarContainer from "../index-page/RequestsAppBarContainer";

const Overview = withAsync({
  useHook: useDispatchShipmentAndAwards,
  Component: ({ loadedData: { shipment, dispatch } }) => {
    const { t } = useTranslation(["requestManagement", "common"]);
    const cancelDialogOpen = useBoolean();
    const resendDialogOpen = useBoolean();
    const [modifyDispatch, { isLoading: isModifyingDispatch }] = useModifyShipperDispatchMutation();
    const [reason, setReason] = useState<string>("");

    const handleCancelDispatch = async () => {
      try {
        await modifyDispatch({
          urlParams: { requestId: dispatch.id },
          body: { status: "CANCELED", canceled_reason: reason },
        }).unwrap();
        cancelDialogOpen.setFalse();
      } catch (e) {
        enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
        Sentry.captureException(e);
      }
    };

    const handleResendDispatch = async () => {
      try {
        await modifyDispatch({
          urlParams: { requestId: dispatch.id },
          body: { status: "SENT" },
        }).unwrap();
        resendDialogOpen.setFalse();
      } catch (e) {
        enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
        Sentry.captureException(e);
      }
    };

    const actions: ComponentProps<typeof DispatchHeaderView>["actions"] = [
      {
        label: t("requestManagement:dispatchHeader_actions_resend_label"),
        onClick: resendDialogOpen.setTrue,
        disabled: dispatch.status !== "SENT",
      },
      {
        label: t("requestManagement:dispatchHeader_actions_cancel_label"),
        onClick: cancelDialogOpen.setTrue,
        disabled: ["DRAFT", "CONFIRMED", "CANCELED", "REJECTED"].includes(dispatch.status),
      },
    ];

    const origin = first(shipment.stops);
    const dest = last(shipment.stops);

    return (
      <AppBar>
        <RequestsAppBarContainer />
        <DispatchHeaderView
          mode={shipment.mode}
          portexId={dispatch.portex_id}
          status={dispatch.status}
          equipment={formatEquipment(t, shipment)}
          originLocation={origin?.address?.city ?? ""}
          destinationLocation={dest?.address?.city ?? ""}
          originDate={
            origin?.start && origin.address?.ianaTimezone
              ? DateTime.fromISO(origin.start, { zone: origin.address.ianaTimezone })
              : null
          }
          destinationDate={
            dest?.start && dest.address?.ianaTimezone
              ? DateTime.fromISO(dest.start, { zone: dest.address.ianaTimezone })
              : null
          }
          actions={actions}
        />
        <CancelDispatchDialogView
          isOpen={cancelDialogOpen.value}
          onSubmit={handleCancelDispatch}
          onClose={cancelDialogOpen.setFalse}
          handleNoteChange={setReason}
          cancelReason={reason}
          loading={isModifyingDispatch}
        />
        <ResendDispatchDialogView
          isOpen={resendDialogOpen.value}
          onSubmit={handleResendDispatch}
          onClose={resendDialogOpen.setFalse}
          loading={isModifyingDispatch}
        />
      </AppBar>
    );
  },
});

export default Overview;
