import { VFC } from "react";

import { CalendarToday } from "@material-ui/icons";
import {
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  InputLabel,
  TextField,
  TextInput,
} from "@portex-pro/ui-components";
import { ShipmentStop } from "app/pages/shipments/types/domain";
import SingleCalendarPicker from "components/SingleCalendarPicker";
import Text from "components/Text";
import { ShipLocation } from "features/generate-bol/store/generateBOLSlice";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import StopSelectView from "./StopSelectView";

interface ShipLocationViewProps {
  shipLocation: ShipLocation;
  stops: ShipmentStop[];
  showDate?: boolean;
  style: "pickup" | "delivery";

  onChangeStop: (stopId: ShipmentStop["id"]) => void;
  onChangeShipLocation: (data: Partial<ShipLocation>) => void;
}

const ShipLocationView: VFC<ShipLocationViewProps> = (props) => {
  const { shipLocation, stops, onChangeStop, showDate = false, style, onChangeShipLocation } = props;
  const { t } = useTranslation("bolGeneration");

  return (
    <div className="flex flex-col w-full bg-white">
      <div className="w-full py-2.5 px-5 bg-grey-100 border-b border-t border-grey-300">
        <Text
          size="medium"
          weight="bold"
          typographyProps={{ style: { textAlign: "center", textTransform: "uppercase" } }}
        >
          {t(`shipLocation_${style}`)}
        </Text>
      </div>
      <div className="p-5 flex flex-col space-y-2.5">
        <FormControl fullWidth>
          <InputLabel htmlFor="ship-from-company-name">{t("companyName")}</InputLabel>
          <TextField
            value={shipLocation.companyName}
            id="ship-from-company-name"
            onChange={(event) => onChangeShipLocation({ companyName: event.target.value })}
          />
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="ship-from-address">{t(`address_${style}`)}</InputLabel>
          <StopSelectView onChange={onChangeStop} selectedStopId={shipLocation.stopId} stops={stops} />
        </FormControl>

        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={shipLocation.includeOperationsContact}
                onChange={(_, checked) => onChangeShipLocation({ includeOperationsContact: checked })}
              />
            }
            label={t("operationsContact_description")}
          />
        </FormControl>
        <Collapse
          in={shipLocation.includeOperationsContact}
          style={{ marginTop: !shipLocation.includeOperationsContact ? 0 : undefined }}
        >
          <FormControl fullWidth>
            <InputLabel>{t("operationsContact")}</InputLabel>
            <div className="flex flex-row w-full space-x-2 mb-2">
              <TextInput
                fullWidth
                value={shipLocation.operationsContact?.firstName}
                onChange={(event) =>
                  onChangeShipLocation({
                    operationsContact: { ...shipLocation.operationsContact, firstName: event.target.value },
                  })
                }
                placeholder={t("firstName_placeholder")}
              />
              <TextInput
                fullWidth
                value={shipLocation.operationsContact?.lastName}
                onChange={(event) =>
                  onChangeShipLocation({
                    operationsContact: { ...shipLocation.operationsContact, lastName: event.target.value },
                  })
                }
                placeholder={t("lastName_placeholder")}
              />
            </div>
            <div className="flex flex-row w-full space-x-2">
              <TextInput
                fullWidth
                value={shipLocation.operationsContact?.email}
                onChange={(event) =>
                  onChangeShipLocation({
                    operationsContact: { ...shipLocation.operationsContact, email: event.target.value },
                  })
                }
                placeholder={t("email_placeholder")}
              />
              <TextInput
                fullWidth
                value={shipLocation.operationsContact?.phoneNumber}
                onChange={(event) =>
                  onChangeShipLocation({
                    operationsContact: { ...shipLocation.operationsContact, phoneNumber: event.target.value },
                  })
                }
                placeholder={t("phoneNumber_placeholder")}
              />
            </div>
          </FormControl>
        </Collapse>

        <FormControl fullWidth style={{ marginTop: 0 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={shipLocation.includeStopNotes}
                onChange={(_, checked) => onChangeShipLocation({ includeStopNotes: checked })}
              />
            }
            label={t("stopNotes_description")}
          />
        </FormControl>
        <Collapse
          in={shipLocation.includeStopNotes}
          style={{ marginTop: !shipLocation.includeOperationsContact ? 0 : undefined }}
        >
          <FormControl fullWidth>
            <InputLabel>{t("stopNotes")}</InputLabel>
            <TextField
              value={shipLocation.stopNotes}
              multiline
              rows={4}
              onChange={(event) => onChangeShipLocation({ stopNotes: event.target.value })}
            />
          </FormControl>
        </Collapse>

        {showDate && (
          <FormControl fullWidth>
            <InputLabel>{t(`date_${style}`)}</InputLabel>
            <SingleCalendarPicker
              value={shipLocation.date ? DateTime.fromISO(shipLocation.date) : null}
              onChange={(date) => onChangeShipLocation({ date: date?.toISO() })}
              InputProps={{ startAdornment: <CalendarToday className="mr-2 ml-1 text-green-500" /> }}
            />
          </FormControl>
        )}
      </div>
    </div>
  );
};

export default ShipLocationView;
