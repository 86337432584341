import { BillOfLadingFormData } from "api/rest/bill-of-lading/types";
import { RequiredNotNull } from "api/types/generated-types";
import { Shipment, ShipmentAccessorialType, ShipmentMode, ShipmentStop } from "app/pages/shipments/types/domain";
import formatAddress from "app/pages/shipments/utils/formatAddress";
import { DateTime } from "luxon";

import { FormData, initialState, PackingGroup, ShipLocation, State } from "../store/generateBOLSlice";

export const mapShipmentStopToShipLocation = (stop: ShipmentStop): ShipLocation => {
  return {
    stopId: stop.id,
    companyName: stop.address?.name,
    date: stop.start,
    includeOperationsContact: false,
    includeStopNotes: false,
    operationsContact: {
      email: stop.address?.contact?.email,
      firstName: stop.address?.contact?.firstName,
      lastName: stop.address?.contact?.lastName,
      phoneNumber: stop.address?.contact?.phoneNumber,
    },
    stopNotes: stop.note ?? undefined,
  };
};

export const mapShipmentStopToFormData = (
  stop: ShipmentStop,
  location: "pickup" | "delivery"
): Partial<Pick<FormData, "shipFrom" | "shipTo">> => {
  if (location === "delivery") {
    return { shipTo: mapShipmentStopToShipLocation(stop) };
  }

  return { shipFrom: mapShipmentStopToShipLocation(stop) };
};

export const mapShipmentToCargo = (shipment: Shipment): PackingGroup[] | undefined => {
  if (shipment.mode === ShipmentMode.Ltl) {
    return shipment.loadSpec.packageGroups?.map(
      (packageGroup): PackingGroup => ({
        class: packageGroup.freightClass,
        commodities: packageGroup.commodities,
        count: packageGroup.itemQuantity,
        item: packageGroup.packagingType,
        nmfc: packageGroup.nmfcCode,
        weight: packageGroup.weightPerPackage,
        xDimension: packageGroup.widthPerPackage,
        yDimension: packageGroup.lengthPerPackage,
        zDimension: packageGroup.heightPerPackage,
      })
    );
  }

  return [
    {
      commodities: shipment.loadSpec.commodities,
      item: shipment.loadSpec.packagingType,
      count: shipment.loadSpec.isPalletized ? shipment.loadSpec.palletCount : shipment.loadSpec.packingCount,
      weight: shipment.loadSpec.totalWeight,
    },
  ];
};

export const mapShipmentToFormData = (shipment: Shipment | undefined): State => {
  const loadId = shipment?.trucks?.[0]?.id;

  if (!shipment || !loadId) {
    return initialState;
  }

  if (shipment.mode !== ShipmentMode.Ftl && shipment.mode !== ShipmentMode.Ltl) {
    return initialState;
  }

  return {
    shipment,
    mode: shipment.mode,
    loadId,
    formData: {
      shipFrom: mapShipmentStopToShipLocation(shipment.stops[0]),
      shipTo: mapShipmentStopToShipLocation(shipment.stops[1]),
      freightTerms: "Prepaid",
      shipFromAccessorials: shipment.accessorials
        ?.filter((accessorial) => accessorial.type === ShipmentAccessorialType.Pickup)
        .map((accessorial) => accessorial.name)
        .join(", "),
      shipToAccessorials: shipment.accessorials
        ?.filter((accessorial) => accessorial.type === ShipmentAccessorialType.Delivery)
        .map((accessorial) => accessorial.name)
        .join(", "),
      loadId: shipment?.trucks?.[0].referenceNumber ?? undefined,
      poReferenceNumber: shipment.referenceNumber,
      cargo: mapShipmentToCargo(shipment),
      carrier: shipment.sourceType === "quote" ? shipment.bookedQuote.carrier_name : undefined,
    },
  };
};

const createOperationsContactString = (
  operationsContact: ShipLocation["operationsContact"] | undefined
): string | undefined => {
  if (!operationsContact) {
    return undefined;
  }

  const name = [operationsContact?.firstName, operationsContact?.lastName].filter(Boolean).join(" ");
  const contactInfo = [operationsContact?.email, operationsContact?.phoneNumber].filter(Boolean).join(", ");

  return [name, contactInfo].filter(Boolean).join(", ");
};

export const mapStateToApiRequest = ({
  shipment,
  formData,
}: RequiredNotNull<Pick<State, "shipment" | "formData">>): BillOfLadingFormData => ({
  shipFromCompanyName: formData.shipFrom.companyName,
  shipFromAddress: formatAddress(
    shipment?.stops.find((stop) => stop.id === formData?.shipFrom.stopId)?.address,
    "long"
  ),
  shipFromOperationsContact: createOperationsContactString(formData.shipFrom.operationsContact),
  shipFromNotes: formData.shipFrom.includeStopNotes ? formData.shipFrom.stopNotes : undefined,
  shipFromDate: formData.shipFrom.date
    ? DateTime.fromISO(formData.shipFrom.date).toLocaleString(DateTime.DATE_SHORT)
    : undefined,

  shipToCompanyName: formData.shipTo.companyName,
  shipToAddress: formatAddress(shipment?.stops.find((stop) => stop.id === formData?.shipTo.stopId)?.address, "long"),
  shipToOperationsContact: createOperationsContactString(formData.shipTo.operationsContact),
  shipToNotes: formData.shipTo.includeStopNotes ? formData.shipTo.stopNotes : undefined,

  thirdPartyFreightBillTo: formData.thirdPartyBillTo,
  freightTermsCollect: formData.freightTerms === "Collect",
  freightTermsMasterBol: formData.freightTerms === "Master BOL",
  freightTermsPrepaid: formData.freightTerms === "Prepaid",
  freightTermsThirdParty: formData.freightTerms === "3rd Party",

  specialInstructions: formData.specialInstructions,

  pickupAccessorials: !!formData.shipFromAccessorials ? [formData.shipFromAccessorials] : undefined,
  deliveryAccessorials: !!formData.shipToAccessorials ? [formData.shipToAccessorials] : undefined,

  billOfLadingNumber: formData.bolNumber,

  carrierName: formData.carrier,
  trailerNumber: formData.trailerNumber,
  loadId: formData.loadId,
  poNumber: formData.poReferenceNumber,
  additionalReferences: formData.additionalReferences,

  cargo: formData.cargo?.map((cargo) => {
    const dimensionsList = [cargo.xDimension, cargo.yDimension, cargo.zDimension].filter(Boolean);
    const dimensions = dimensionsList.length ? dimensionsList.join("in x").concat("in") : undefined;

    return {
      class: cargo.class?.split("_").slice(1).join("."),
      dimensions,
      weight: cargo.weight,
      weightUnit: "LB",
      hazardous: formData.isHazardous,
      items: cargo.item,
      count: cargo.count,
      nmfc: cargo.nmfc,
      commodities: cargo.commodities,
    };
  }),
  totalWeight: formData.cargo?.reduce((weight, cargo) => weight + (cargo.weight ?? 0), 0),
  weightUnit: "LB",

  declaredValueAmount: formData.declaredValueAmount,
  declaredValueUnit: formData.declaredValueUnit,

  remitCashOnDeliveryTo: formData.remitCodTo,
  cashOnDeliveryAmount: formData.codAmount,
  feeTermsPrepaid: formData.feeTerms === "prepaid",
  feeTermsCollect: formData.feeTerms === "collect",
  feeTermsCustomerCheck: formData.feeTerms === "customerCheckAcceptable",
});
