import { FC, useMemo, useRef, useState } from "react";

import { Box, Collapse, Summary, Typography } from "@portex-pro/ui-components";
import { Truck } from "api/graphql/generated";
import { QuoteRequest } from "api/types/generated-types";
import clamp from "lodash/clamp";
import keyBy from "lodash/keyBy";
import uniqueId from "lodash/uniqueId";
import values from "lodash/values";
import { useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";

import ButtonEditDetailsView from "../../components/ButtonEditDetailsView";
import useFtlReviewBookingStyles from "../../hooks/useFtlReviewBookingStyles";
import PoDetailsFooterView from "./PoDetailsFooterView";
import PoDetailsRowView from "./PoDetailsRowView";

const MIN_NUMBER_OF_TRUCKS = 1;
const MAX_NUMBER_OF_TRUCKS = 100;

export interface TrucksEditViewProps {
  quoteRequest: QuoteRequest;
  trucks: Array<Pick<Truck, "id" | "trailer_size" | "trailer_type" | "reference_number">>;
  onChangeTrucks: (trucks: Array<Pick<Truck, "id" | "reference_number">>) => void;
  onChangeQuoteRequest: (referenceNumber: string | undefined) => void;
  onDiscard: () => void;
  loading: boolean;
  onClickEdit: () => void;
  disabledEdit: boolean;
  isEditing: boolean;
  singleTruck?: boolean;
  showNumberOfTrucks?: boolean;
}

const PoDetailsView: FC<TrucksEditViewProps> = ({
  quoteRequest,
  trucks,
  onChangeTrucks,
  onChangeQuoteRequest,
  onDiscard,
  loading,

  onClickEdit,
  disabledEdit,
  isEditing,
  singleTruck,
  showNumberOfTrucks = false,
}) => {
  const { t } = useTranslation("quoteDetails");
  const classes = useFtlReviewBookingStyles();
  const poDetailsEl = useRef<HTMLDivElement>();

  const dirty = useBoolean(false);
  const [trucksById, setTrucksById] = useState(keyBy(trucks, "id"));
  const isQrDirty = useBoolean(false);
  const [qrReference, setQrReference] = useState<string | undefined>(quoteRequest.reference_number ?? undefined);

  const [isOpen, setIsOpen] = useState(false);

  const scrollWhenOutOfView = () => {
    console.log(poDetailsEl.current?.getBoundingClientRect().top);
    if ((poDetailsEl.current?.getBoundingClientRect().top ?? 0) < 0) {
      document
        .getElementsByTagName("main")[0]
        .scrollBy({ behavior: "smooth", top: (poDetailsEl.current?.getBoundingClientRect().top ?? 0) - 200 });
    }
  };

  const handleDiscard = () => {
    setTrucksById(keyBy(trucks, "id"));
    setQrReference(quoteRequest.reference_number ?? undefined);
    scrollWhenOutOfView();
    onDiscard();
    dirty.setFalse();
  };

  const handleSave = () => {
    scrollWhenOutOfView();
    if (dirty.value) {
      onChangeTrucks(values(trucksById));
    }

    if (isQrDirty.value) {
      onChangeQuoteRequest(qrReference);
    }

    if (!dirty.value && !isQrDirty.value) {
      onDiscard();
    }
  };

  const headingComponent = (
    <>
      <Typography>
        <strong>{t("poReferenceDetails_title")}</strong>
      </Typography>
      <ButtonEditDetailsView onClick={onClickEdit} disabled={disabledEdit || loading} />
    </>
  );

  /**
   * @todo Once 'editTrucksV2' is enabled for all users:
   * @todo refactor to remove `setTrucksById`, and just update the entire array of trucks.
   * @todo write new function `const handleChangeTrucks = (trucks: { reference_number: string }[]): void => `
   */
  const handleChangeTruck = (truckId: string) => (poNumber: string) => {
    setTrucksById((prev) => {
      const prevTruck = prev[truckId];
      dirty.setTrue();
      return { ...prev, [truckId]: { ...prevTruck, reference_number: poNumber } };
    });
  };

  const handleChangeNumberOfTrucks = (value: number) => {
    if (value < MIN_NUMBER_OF_TRUCKS) {
      // this is an invalid number of trucks, do nothing.
      return;
    }
    const newTruckCount = clamp(value, MAX_NUMBER_OF_TRUCKS);

    setTrucksById((prev) => {
      const prevTrucks = values(prev);
      const delta = newTruckCount - prevTrucks.length;
      let newTrucks = Array.from(prevTrucks);

      // If there's a reduction of trucks, just slice off trucks from the end of the array
      if (delta < 0) {
        dirty.setTrue();
        newTrucks = prevTrucks.slice(0, newTruckCount);
      }

      // If there's an addition of trucks, append new trucks to the end of the array with a unique placeholder id.
      // The unique placeholder id is necessary because the underlying code of changing each individual truck relies on the id
      if (delta > 0) {
        dirty.setTrue();
        const additionalTrucks = Array.from({ length: delta }).map(() => ({
          id: uniqueId("_placeholder_truck_id_"),
          reference_number: "",
          trailer_size: trucks[0]?.trailer_size,
          trailer_type: trucks[0]?.trailer_type,
        }));
        newTrucks = prevTrucks.concat(additionalTrucks);
      }

      if (newTrucks.length === 1) {
        setIsOpen(false);
      }
      if (prevTrucks.length === 1 && newTrucks.length > 1) {
        setIsOpen(true);
      }

      // return the array the original structure of `trucksById`
      return keyBy(newTrucks, "id");
    });
  };

  const trucksArr = useMemo(() => Object.values(trucksById), [trucksById]);

  return (
    <Box py={2} id="po-details-view">
      <Summary className={classes.customEditSummary} innerRef={poDetailsEl}>
        <Summary.Head heading={headingComponent} />
        <PoDetailsRowView.QR
          isEditing={isEditing}
          loading={loading}
          quoteRequestRefNumber={qrReference}
          onChange={(value) => {
            setQrReference(value);
            isQrDirty.setTrue();
          }}
        />
        <PoDetailsRowView.NumberOfTrucks
          value={trucksArr.length}
          onChange={handleChangeNumberOfTrucks}
          hide={!showNumberOfTrucks}
          disabled={!isEditing || loading}
        />
        {!!trucksArr.length && (
          <Collapse in={isOpen} timeout={1000 + 250 * (trucksArr.length / 10)}>
            {trucksArr.map((truck, index) => (
              <PoDetailsRowView.Truck
                singleTruck={singleTruck}
                index={index}
                isEditing={isEditing}
                loading={loading}
                truck={truck}
                onChange={handleChangeTruck(truck.id)}
                key={truck.id}
              />
            ))}
          </Collapse>
        )}

        <PoDetailsFooterView
          handleDiscard={handleDiscard}
          handleSave={handleSave}
          isEditing={isEditing}
          isOpen={isOpen}
          loading={loading}
          trucks={trucksArr}
          onClickOpen={() => setIsOpen((prev) => !prev)}
        />
      </Summary>
    </Box>
  );
};

export default PoDetailsView;
