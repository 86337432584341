import isNumber from "lodash/isNumber";
import { formatTrailerType } from "utils/formatTrailerType";

import { LtlLoadSpec, LtlTrailerType, Optional, TempUnit } from "../../../../../api/types/generated-types";

export const getLtlTrailerColumnLabel = (ltlLoadSpec: Optional<LtlLoadSpec>): string => {
  if (!ltlLoadSpec) return "";
  const { min_temp, max_temp, trailer_type } = ltlLoadSpec;

  const trailerType = formatTrailerType(trailer_type);

  const maybeMinTemp = isNumber(min_temp) ? `${min_temp}˚${TempUnit.F}` : "";
  const maybeMinMaxTemp = isNumber(max_temp) ? `${max_temp}˚${TempUnit.F}` : "";

  const maybeTemps =
    trailer_type === LtlTrailerType.Reefer && (min_temp || max_temp)
      ? `(${[maybeMinTemp, maybeMinMaxTemp].filter(Boolean).join(" to ")})`
      : "";

  return [trailerType, maybeTemps].filter(Boolean).join(" ");
};
