import { VFC } from "react";

import { FormControl, InputLabel, TextInput } from "@portex-pro/ui-components";
import Text from "components/Text";
import { FormData } from "features/generate-bol/store/generateBOLSlice";
import { useTranslation } from "react-i18next";

interface CarrierViewProps {
  carrier?: string;
  trailerNumber?: string;
  loadId?: string;
  poReferenceNumber?: string;
  additionalReferences?: string;

  onChange: (
    data: Partial<Pick<FormData, "carrier" | "trailerNumber" | "loadId" | "poReferenceNumber" | "additionalReferences">>
  ) => void;
}

const CarrierView: VFC<CarrierViewProps> = (props) => {
  const { onChange, carrier, loadId, poReferenceNumber, trailerNumber, additionalReferences } = props;
  const { t } = useTranslation("bolGeneration");

  return (
    <div className="flex flex-col w-full bg-white">
      <div className="w-full py-2.5 px-5 bg-grey-100 border-b border-t border-grey-300">
        <Text
          size="medium"
          weight="bold"
          typographyProps={{ style: { textAlign: "center", textTransform: "uppercase" } }}
        >
          {t("carrier")}
        </Text>
      </div>
      <div className="p-5 flex flex-col space-y-2.5">
        <FormControl fullWidth>
          <InputLabel>{t("carrier")}</InputLabel>
          <TextInput value={carrier} onChange={(event) => onChange({ carrier: event.target.value })} />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("trailerNumber")}</InputLabel>
          <TextInput value={trailerNumber} onChange={(event) => onChange({ trailerNumber: event.target.value })} />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("loadId")}</InputLabel>
          <TextInput value={loadId} onChange={(event) => onChange({ loadId: event.target.value })} />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("poReferenceNumber")}</InputLabel>
          <TextInput
            value={poReferenceNumber}
            onChange={(event) => onChange({ poReferenceNumber: event.target.value })}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("additionalReferences")}</InputLabel>
          <TextInput
            value={additionalReferences}
            multiline
            rows={3}
            rowsMax={8}
            InputProps={{ style: { paddingTop: 0 } }}
            onChange={(event) => onChange({ additionalReferences: event.target.value })}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default CarrierView;
