import i18n from "app/i18n";
import OptionalMaybe from "types/OptionalMaybe";
import { TrailerType, TrailerTypeEnum } from "types/TrailerType";

export const formatTrailerType = (type: OptionalMaybe<TrailerType>): string => {
  switch (type) {
    case TrailerTypeEnum.DRY_VAN:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    case TrailerTypeEnum.FLATBED:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    case TrailerTypeEnum.RGN:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    case TrailerTypeEnum.REEFER:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    case TrailerTypeEnum.FOOD_GRADE_TANK:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    case TrailerTypeEnum.BULK:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    case TrailerTypeEnum.BOX_TRUCK:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    case TrailerTypeEnum.SPRINTER_VAN:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    case TrailerTypeEnum.STEP_DECK:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    case TrailerTypeEnum.CONESTOGA:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    case TrailerTypeEnum.HOTSHOT:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    case TrailerTypeEnum.NA_PARTNERS_CHOICE:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    case TrailerTypeEnum.DRAYAGE:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    case TrailerTypeEnum.DRAYAGE_REEFER:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    case TrailerTypeEnum.CARGO_VAN:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    case TrailerTypeEnum.DUMP:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    case TrailerTypeEnum.PARTIAL_LOAD:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    case TrailerTypeEnum.OTHER:
      return i18n.t(`trailerTypes.${type}`, { ns: "common" });
    default: {
      return "";
    }
  }
};
