import { AnyAction, ListenerEffectAPI, ThunkDispatch } from "@reduxjs/toolkit";
import shipmentsApi from "api/rest/shipments/shipmentsApi";
import { downloadBlobFromUrl } from "utils/downloadBlobFromUrl";

import { NotifyOnSocketListenEventMap } from "../../../sockets/types";

export const fileDownloadReadyMatcher = (
  action: AnyAction
): action is NotifyOnSocketListenEventMap["file-download-ready"] => {
  return action.type === "socket/listenEvent/file-download-ready";
};
export const fileDownloadReadyListener =
  (fileDownloadUUID: string) =>
  (
    action: AnyAction,
    listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>
  ): void => {
    if (fileDownloadReadyMatcher(action) && action.payload.fileDownloadUUID === fileDownloadUUID) {
      downloadBlobFromUrl(action.payload.downloadUrl, action.payload.fileName);

      if (action.payload.shipmentId) {
        listenerApi.dispatch(shipmentsApi.util.invalidateTags([{ type: "shipment", id: action.payload.shipmentId }]));
      }
    }

    listenerApi.unsubscribe();
    listenerApi.cancelActiveListeners();
  };
