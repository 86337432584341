import { VFC } from "react";

import { AddCircleOutline } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import Text from "components/Text";
import { PackingGroup as PackingGroupType } from "features/generate-bol/store/generateBOLSlice";
import { useTranslation } from "react-i18next";

import PackingGroupView from "./PackingGroupView";

interface CargoProps {
  cargo: PackingGroupType[];
  onChangeCargoDetail: (changes: Partial<PackingGroupType>, index: number) => void;
  onAddAnotherItem: () => void;
}

const CargoView: VFC<CargoProps> = (props) => {
  const { cargo, onChangeCargoDetail, onAddAnotherItem } = props;
  const { t } = useTranslation("bolGeneration");

  const totalWeight = cargo.reduce((total, packingGroup) => total + (packingGroup.weight ?? 0), 0);

  return (
    <div className="grid grid-cols-[1fr_1fr_0.7fr_1.3fr_1fr_1fr] border-b border-grey-300 bg-white [&_>_div:not(:first-child)]:px-2 [&_>_div:not(:first-child)]:py-2 [&_>_div]:border-grey-300">
      <div className="p-5 col-start-1 col-span-full border-b border-t bg-grey-100">
        <Text size="medium" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("cargoDetails")}
        </Text>
      </div>
      <div className="col-start-1 col-span-4 border-r border-b">
        <Text
          size="small"
          weight="bold"
          typographyProps={{ style: { textTransform: "uppercase", paddingLeft: ".75rem" } }}
        >
          {t("handlingUnit")}
        </Text>
      </div>
      <div className="col-start-5 col-span-2 border-b">
        <Text
          size="small"
          weight="bold"
          typographyProps={{ style: { textTransform: "uppercase", textAlign: "center" } }}
        >
          {t("ltlOnly")}
        </Text>
      </div>
      <div className="col-start-1 border-b">
        <Text
          size="small"
          weight="bold"
          typographyProps={{ style: { textTransform: "uppercase", paddingLeft: ".75rem" } }}
        >
          {t("item")}
        </Text>
      </div>
      <div className="col-start-2 border-b">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("weightTotal")}
        </Text>
      </div>
      <div className="col-start-3 border-b">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("dimensions")}
        </Text>
      </div>
      <div className="col-start-4 border-b border-r">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("commodities")}
        </Text>
      </div>
      <div className="col-start-5 border-b">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("nmfc")}
        </Text>
      </div>
      <div className="col-start-6 border-b">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("class")}
        </Text>
      </div>
      {cargo.map((packingGroup, index) => (
        <PackingGroupView
          packingGroup={packingGroup}
          // We want the packaging groups to start in the 4th row
          gridPosition={index + 4}
          packingGroupIndex={index}
          onChangeCargoDetail={onChangeCargoDetail}
        />
      ))}
      <div className="col-span-full border-t flex flex-row items-center justify-between">
        <Button onClick={onAddAnotherItem} startIcon={<AddCircleOutline />} color="primary">
          <Text size="small" weight="bold">
            {t("addAnotherItem")}
          </Text>
        </Button>
        <Text size="small">{t("totalWeight", { totalWeight })}</Text>
      </div>
    </div>
  );
};

export default CargoView;
