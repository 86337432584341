import { TrailerTypeEnum } from "types/TrailerType";

import BidRequestProgressionView from "../../components/BidRequestProgressionView";
import withBidRequest from "../../components/withBidRequest";
import useChangeBidRequestRoute from "../../hooks/useChangeBidRequestRoute";
import useUpdateContractRequest from "../../hooks/useUpdateContractRequest";
import { useUpdateBidRequest } from "../../store/bidRequestContractSlice";
import { useBidRequestSliceSelector } from "../../store/bidRequestStore";
import BidRequestPageSteps from "../../types/BidRequestPageSteps";
import TrailerSelectionView from "./components/TrailerSelectionView";

const TrailerSelectionContainer = withBidRequest(() => {
  const bidRequestState = useBidRequestSliceSelector((state) => state.bidRequestContractSlice);
  const [updateContractRequest] = useUpdateContractRequest();
  const updateBidRequest = useUpdateBidRequest();
  const changeBidRequestRoute = useChangeBidRequestRoute();

  const nextDisabled = !bidRequestState.trailer_type || !bidRequestState.trailer_size;

  const onNext = () => {
    updateContractRequest();
    changeBidRequestRoute({ step: BidRequestPageSteps.ContractLength });
  };

  return (
    <BidRequestProgressionView
      fullPage
      onBack={() => changeBidRequestRoute({ step: "initial" })}
      onNext={onNext}
      nextProps={{ disabled: nextDisabled }}
    >
      <TrailerSelectionView
        minTemp={bidRequestState.min_temp}
        maxTemp={bidRequestState.max_temp}
        type={bidRequestState.trailer_type}
        size={bidRequestState.trailer_size}
        onChangeType={(value) => {
          updateBidRequest({ trailer_type: value });
          if (value === TrailerTypeEnum.DRAYAGE || value === TrailerTypeEnum.DRAYAGE_REEFER) {
            updateBidRequest({ trailer_size: undefined });
          }
        }}
        onChangeSize={(value) => updateBidRequest({ trailer_size: value })}
        onChangeMinTemp={(value) => updateBidRequest({ min_temp: value })}
        onChangeMaxTemp={(value) => updateBidRequest({ max_temp: value })}
      />
    </BidRequestProgressionView>
  );
});

export default TrailerSelectionContainer;
