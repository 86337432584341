import { VFC } from "react";

import { Box, Button } from "@portex-pro/ui-components";
import ButtonSelectView from "components/ButtonSelectView";
import Text from "components/Text";
import values from "lodash/values";
import { getFtlTrailerSize } from "pages/shipper/utils/getFtlTrailerSize";
import { useTranslation } from "react-i18next";
import OptionalMaybe from "types/OptionalMaybe";
import { TrailerSize, TrailerSizeDrayage, TrailerSizeEnum } from "types/TrailerSize";
import { TrailerType, TrailerTypeEnum } from "types/TrailerType";
import { formatTrailerType } from "utils/formatTrailerType";

import FormRadioSelectionView from "./FormRadioSelectionView";
import ReeferTempRangeView from "./ReeferTempRangeView";

const { DRY_VAN, FLATBED, REEFER, ...others } = TrailerTypeEnum;
const otherTrailerTypes = values(others);
const primaryTrailerTypes = [DRY_VAN, FLATBED, REEFER];
const trailerSizes: TrailerSize[] = [
  TrailerSizeEnum.T_53,
  TrailerSizeEnum.T_48,
  TrailerSizeEnum.T_40,
  TrailerSizeEnum.T_26,
  TrailerSizeEnum.NA,
];

const drayageTrailerSizes: TrailerSizeDrayage[] = ["C_20", "C_20_HC", "C_40", "C_40_HC", "C_45_HC"];

type TrailerSelectionViewProps = {
  type: OptionalMaybe<TrailerType>;
  size: OptionalMaybe<TrailerSize>;
  minTemp: OptionalMaybe<number>;
  maxTemp: OptionalMaybe<number>;
  onChangeType: (val: TrailerType) => void;
  onChangeSize: (val: TrailerSize) => void;
  onChangeMinTemp: (val: number) => void;
  onChangeMaxTemp: (val: number) => void;
};

const TrailerSelectionView: VFC<TrailerSelectionViewProps> = (props) => {
  const { type, size, minTemp, maxTemp, onChangeType, onChangeSize, onChangeMinTemp, onChangeMaxTemp } = props;
  const { t } = useTranslation("shipper");

  const hasSelectedType = !!type;
  const isTemperatureControlled =
    type === TrailerTypeEnum.REEFER ||
    type === TrailerTypeEnum.FOOD_GRADE_TANK ||
    type === TrailerTypeEnum.DRAYAGE_REEFER;

  return (
    <Box p={2} display="flex" flexDirection="column" alignItems="center">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gridRowGap={20}
        maxWidth="md"
      >
        <Text size="x-large" weight="bold">
          {t("bids.bidRequestPage.pageTrailerSelection.trailerType_label")}
        </Text>

        <Box py={2} display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" gridGap={20} flexWrap="wrap" justifyContent="center" maxWidth={800}>
            {primaryTrailerTypes.map((trailerType) => (
              <Button
                size="large"
                color="primary"
                variant={trailerType === type ? "contained" : "outlined"}
                style={{ minWidth: 150, whiteSpace: "nowrap" }}
                key={trailerType}
                onClick={() => onChangeType(trailerType)}
              >
                {formatTrailerType(trailerType)}
              </Button>
            ))}
            <ButtonSelectView
              items={otherTrailerTypes.map((type) => ({
                value: type,
                label: formatTrailerType(type),
              }))}
              value={type ?? undefined}
              placeholder={t("bids.bidRequestPage.pageTrailerSelection.trailerType_others_placeholder")}
              onChange={onChangeType}
              buttonProps={{ style: { minWidth: 150, whiteSpace: "nowrap" } }}
            />
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" width={670}>
          {hasSelectedType && (
            <FormRadioSelectionView
              formControlProps={{ fullWidth: true }}
              value={size ?? undefined}
              items={
                type === TrailerTypeEnum.DRAYAGE || type === TrailerTypeEnum.DRAYAGE_REEFER
                  ? drayageTrailerSizes
                  : trailerSizes
              }
              onChange={onChangeSize}
              getItemCopy={(item) => {
                if (item === TrailerSizeEnum.NA) {
                  return t("n/a");
                }

                return `${getFtlTrailerSize(item)} ${formatTrailerType(type)}`;
              }}
              label={t("bids.bidRequestPage.pageTrailerSelection.trailerSize_label")}
            />
          )}

          {isTemperatureControlled && (
            <ReeferTempRangeView
              min={minTemp ?? undefined}
              max={maxTemp ?? undefined}
              onChangeMin={onChangeMinTemp}
              onChangeMax={onChangeMaxTemp}
              boxProps={{ width: "100%" }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TrailerSelectionView;
