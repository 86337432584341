import { VFC } from "react";

import { useSetIsShareDialogOpen } from "app/pages/shipments/store/shipmentDocumentsSlice";
import { useShipmentsSliceSelector } from "app/pages/shipments/store/shipmentsStore";
import useLDFlag from "hooks/useLDFlag";

import DocumentsBulkActionsView from "../views/DocumentsBulkActionsView";
import ShareDocsDialogView from "../views/ShareDocsDialogView";

const DocumentsBulkActionsContainer: VFC = () => {
  const enableShareDocuments = useLDFlag("enableShareDocuments");
  const { selectedDocuments, isShareDialogOpen } = useShipmentsSliceSelector((state) => state.shipmentDocumentsSlice);
  const setIsShareDialogOpen = useSetIsShareDialogOpen();
  const documentsSelectedCount = selectedDocuments.length;

  if (!enableShareDocuments) {
    return null;
  }

  return (
    <>
      <DocumentsBulkActionsView
        documentsSelectedCount={documentsSelectedCount}
        onClickShare={() => setIsShareDialogOpen(true)}
      />
      <ShareDocsDialogView
        documentsSelectedCount={documentsSelectedCount}
        dialogProps={{
          open: isShareDialogOpen,
          onClose: () => setIsShareDialogOpen(false),
        }}
        onClickShareInChat={() => alert("@todo implement me")}
        onClickSendEmail={() => alert("@todo implement me")}
        onChangeEmail={() => alert("@todo implement me")}
      />
    </>
  );
};

export default DocumentsBulkActionsContainer;
