import { VFC } from "react";

import ComingSoon from "components/ComingSoon";
import GenerateBOLShipper from "features/generate-bol/GenerateBOLShipper";
import { useNotificationWatcher } from "hooks/notifications/useNotificationWatcher";
import useLDFlag from "hooks/useLDFlag";

import { Shipment } from "../../types/domain";

interface GenerateBOLContainerProps {
  shipment: Shipment;
}

const GenerateBOLContainer: VFC<GenerateBOLContainerProps> = (props) => {
  const { shipment } = props;
  const enableBOL = useLDFlag("enableBOL");
  useNotificationWatcher([`bolGenerationComplete:${shipment.id}`]);

  if (!enableBOL) {
    return <ComingSoon />;
  }

  return <GenerateBOLShipper shipment={shipment} />;
};

export default GenerateBOLContainer;
